import React from 'react';
import VendorCard from './VendorCard';
import VendorComparison from './VendorComparison';
import { Vendor } from '../../pages/VendorList';
import { useToast } from '../../hooks/useToast';
import { Button } from '../ui/Button';
import { X } from 'lucide-react';
import { supabase } from '../../lib/supabase';

interface VendorGridProps {
  filters: {
    category: string;
    search: string;
    rating: number;
    verified: boolean;
    serviceArea: string;
    services: string[];
  };
}

export default function VendorGrid({ filters }: VendorGridProps) {
  const { showToast } = useToast();
  const [favorites, setFavorites] = React.useState<number[]>([]);
  const [selectedVendors, setSelectedVendors] = React.useState<number[]>([]);
  const [showComparison, setShowComparison] = React.useState(false);
  const [vendors, setVendors] = React.useState<Vendor[]>([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [currentPage, setCurrentPage] = React.useState(1);
  const vendorsPerPage = 12;

  React.useEffect(() => {
    async function fetchVendors() {
      try {
        setIsLoading(true);
        const { data, error } = await supabase
          .from('Vendor_List')
          .select('*') as { data: Vendor[] | null; error: Error | null };

        if (error) {
          showToast({
            type: 'error',
            message: 'Failed to load vendors'
          });
          return;
        }

        setVendors(data || []);
      } catch (error) {
        showToast({
          type: 'error',
          message: 'Failed to load vendors'
        });
      } finally {
        setIsLoading(false);
      }
    }

    fetchVendors();
  }, [showToast]);

  // Reset to first page when filters change
  React.useEffect(() => {
    setCurrentPage(1);
  }, [filters]);

  // Filter vendors based on the filters prop
  const filteredVendors = React.useMemo(() => {
    return vendors.filter((vendor) => {
      const matchesCategory = !filters.category || vendor.VendorType.toLowerCase().includes(filters.category.toLowerCase());
      const matchesSearch = !filters.search || 
        vendor.VendorName.toLowerCase().includes(filters.search.toLowerCase()) ||
        vendor.Notes.toLowerCase().includes(filters.search.toLowerCase());
      const matchesServiceArea = !filters.serviceArea || 
        `${vendor.City} ${vendor.State}`.toLowerCase().includes(filters.serviceArea.toLowerCase());
      const matchesServices = filters.services.length === 0 || 
        filters.services.some(service => vendor.VendorSubType.toLowerCase().includes(service.toLowerCase()));

      return matchesCategory && matchesSearch && matchesServiceArea && matchesServices;
    });
  }, [vendors, filters]);

  // Get current vendors for pagination
  const indexOfLastVendor = currentPage * vendorsPerPage;
  const indexOfFirstVendor = indexOfLastVendor - vendorsPerPage;
  const currentVendors = filteredVendors.slice(indexOfFirstVendor, indexOfLastVendor);
  const totalPages = Math.ceil(filteredVendors.length / vendorsPerPage);

  const toggleFavorite = (vendorId: number) => {
    setFavorites((prev) =>
      prev.includes(vendorId)
        ? prev.filter((id) => id !== vendorId)
        : [...prev, vendorId]
    );
    showToast({
      type: 'success',
      message: `Vendor ${
        favorites.includes(vendorId) ? 'removed from' : 'added to'
      } favorites`
    });
  };

  const toggleVendorSelection = (vendorId: number) => {
    setSelectedVendors((prev) =>
      prev.includes(vendorId)
        ? prev.filter((id) => id !== vendorId)
        : selectedVendors.length < 3
        ? [...prev, vendorId]
        : prev
    );

    if (selectedVendors.length >= 3 && !selectedVendors.includes(vendorId)) {
      showToast({
        type: 'error',
        message: 'You can only compare up to 3 vendors at a time'
      });
    }
  };

  return (
    <div className="relative">
      {isLoading ? (
        <div className="flex justify-center items-center min-h-[200px]">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
        </div>
      ) : (
        <>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-4">
            {currentVendors.map((vendor) => (
              <VendorCard
                key={vendor.ID}
                vendor={vendor}
                isFavorite={favorites.includes(vendor.ID)}
                isSelected={selectedVendors.includes(vendor.ID)}
                onFavoriteClick={() => toggleFavorite(vendor.ID)}
                onCompareClick={() => toggleVendorSelection(vendor.ID)}
              />
            ))}
          </div>

          {/* Pagination Controls */}
          {totalPages > 1 && (
            <div className="flex justify-center items-center gap-4 mt-6 mb-8">
              <Button
                variant="outline"
                onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                disabled={currentPage === 1}
              >
                Previous
              </Button>
              <span className="text-sm">
                Page {currentPage} of {totalPages}
              </span>
              <Button
                variant="outline"
                onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
                disabled={currentPage === totalPages}
              >
                Next
              </Button>
            </div>
          )}

          {selectedVendors.length > 0 && (
            <div className="fixed bottom-4 right-4 bg-white p-4 rounded-lg shadow-lg">
              <div className="flex items-center justify-between mb-2">
                <span className="font-semibold">
                  {selectedVendors.length} vendor(s) selected
                </span>
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={() => setSelectedVendors([])}
                >
                  <X className="h-4 w-4" />
                </Button>
              </div>
              <Button
                className="w-full"
                onClick={() => setShowComparison(true)}
              >
                Compare Vendors
              </Button>
            </div>
          )}

          {showComparison && (
            <VendorComparison
              vendors={vendors.filter((v) => selectedVendors.includes(v.ID))}
              onClose={() => {
                setShowComparison(false);
                setSelectedVendors([]);
              }}
            />
          )}
        </>
      )}
    </div>
  );
}