import React from 'react';
import { ChevronLeft, ChevronRight, Star } from 'lucide-react';
import { motion } from 'framer-motion';

export default function VendorCarousel() {
  const [currentSlide, setCurrentSlide] = React.useState(0);

  const featuredVendors = [
    {
      id: '1',
      name: 'Premium Fuel Supply Co.',
      rating: 4.8,
      image: 'https://tminurcnztragojlogjv.supabase.co/storage/v1/object/sign/Stock%20Photos/stock%20gas%20company%20pic.jfif?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJTdG9jayBQaG90b3Mvc3RvY2sgZ2FzIGNvbXBhbnkgcGljLmpmaWYiLCJpYXQiOjE3MzE2Mzg0MjAsImV4cCI6MTc2MzE3NDQyMH0.53u1qzzfMjF2BjPbxboFxKNPt8iVI4CIXVir3gvPD_c&t=2024-11-15T02%3A40%3A20.349Z',
      description: 'Leading fuel supplier with 24/7 delivery service'
    },
    {
      id: '2',
      name: 'TechPump Solutions',
      rating: 4.6,
      image: 'https://tminurcnztragojlogjv.supabase.co/storage/v1/object/sign/Stock%20Photos/stock%20fuel%20pump%20company%20pic.jfif?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJTdG9jayBQaG90b3Mvc3RvY2sgZnVlbCBwdW1wIGNvbXBhbnkgcGljLmpmaWYiLCJpYXQiOjE3MzE2MzgyODAsImV4cCI6MTc2MzE3NDI4MH0.1rTYsvf_S_2lxNDWCiUxgyFtzxod2a-_U_WarRoIv3U&t=2024-11-15T02%3A38%3A00.936Z',
      description: 'Expert pump maintenance and repair services'
    },
    {
      id: '3',
      name: 'EcoCompliance Services',
      rating: 4.9,
      image: 'https://images.unsplash.com/photo-1560472354-b33ff0c44a43?auto=format&fit=crop&q=80',
      description: 'Environmental compliance and certification experts'
    }
  ];

  const nextSlide = () => {
    setCurrentSlide((prev) =>
      prev === featuredVendors.length - 1 ? 0 : prev + 1
    );
  };

  const prevSlide = () => {
    setCurrentSlide((prev) =>
      prev === 0 ? featuredVendors.length - 1 : prev - 1
    );
  };

  return (
    <div className="relative bg-white rounded-xl shadow-lg overflow-hidden">
      <div className="relative h-96">
        {featuredVendors.map((vendor, index) => (
          <motion.div
            key={vendor.id}
            className="absolute inset-0"
            initial={{ opacity: 0 }}
            animate={{ opacity: index === currentSlide ? 1 : 0 }}
            transition={{ duration: 0.5 }}
          >
            <img
              src={vendor.image}
              alt={vendor.name}
              className="w-full h-full object-cover"
            />
            <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent" />
            <div className="absolute bottom-0 left-0 right-0 p-8 text-white">
              <h3 className="text-2xl font-bold mb-2">{vendor.name}</h3>
              <div className="flex items-center gap-2 mb-2">
                <Star className="w-5 h-5 fill-current text-amber-400" />
                <span className="font-medium">{vendor.rating}</span>
              </div>
              <p className="text-gray-200">{vendor.description}</p>
            </div>
          </motion.div>
        ))}
      </div>

      <button
        onClick={prevSlide}
        className="absolute left-4 top-1/2 -translate-y-1/2 p-2 rounded-full bg-white/80 hover:bg-white transition-colors"
      >
        <ChevronLeft className="w-6 h-6" />
      </button>

      <button
        onClick={nextSlide}
        className="absolute right-4 top-1/2 -translate-y-1/2 p-2 rounded-full bg-white/80 hover:bg-white transition-colors"
      >
        <ChevronRight className="w-6 h-6" />
      </button>

      <div className="absolute bottom-4 left-1/2 -translate-x-1/2 flex gap-2">
        {featuredVendors.map((_, index) => (
          <button
            key={index}
            onClick={() => setCurrentSlide(index)}
            className={`w-2 h-2 rounded-full transition-colors ${
              index === currentSlide ? 'bg-white' : 'bg-white/50'
            }`}
          />
        ))}
      </div>
    </div>
  );
}