import React from 'react';
import { Star, MapPin, Phone, Mail, Shield, ArrowRight, Scale } from 'lucide-react';
import { Vendor } from '../../pages/VendorList';

interface VendorCardProps {
  vendor: Vendor;
  onFavoriteClick: (vendorId: number) => void;
  onCompareClick: (vendorId: number) => void;
  isFavorite: boolean;
  isSelected: boolean;
}

export default function VendorCard({
  vendor,
  onFavoriteClick,
  onCompareClick,
  isFavorite,
  isSelected
}: VendorCardProps) {
  return (
    <div className="bg-white rounded-xl shadow-lg overflow-hidden hover:shadow-xl transition-shadow">
      <div className="relative">
        <img
          src={vendor.Picture}
          alt={vendor.VendorName}
          className="w-full h-48 object-cover"
        />
        <div className="absolute top-2 right-2 flex gap-2">
          <button
            onClick={() => onFavoriteClick(vendor.ID)}
            className={`p-2 rounded-full ${
              isFavorite ? 'bg-blue-600 text-white' : 'bg-white text-gray-600'
            }`}
          >
            <Star className="w-5 h-5" />
          </button>
          <button
            onClick={() => onCompareClick(vendor.ID)}
            className={`p-2 rounded-full ${
              isSelected ? 'bg-blue-600 text-white' : 'bg-white text-gray-600'
            }`}
          >
            <Scale className="w-5 h-5" />
          </button>
        </div>
      </div>

      <div className="p-6">
        <div className="flex items-start justify-between mb-4">
          <div>
            <h3 className="text-xl font-semibold mb-1">{vendor.VendorName}</h3>
            <div className="flex items-center gap-2">
              <div className="flex items-center gap-1 text-blue-600">
                <span className="text-sm">{vendor.VendorType}</span>
              </div>
            </div>
          </div>
        </div>

        <p className="text-gray-600 mb-4 line-clamp-2">
          {vendor.Notes}
        </p>

        <div className="space-y-2 mb-4">
          <div className="flex items-center gap-2 text-gray-600">
            <MapPin className="w-4 h-4" />
            <span className="text-sm">{`${vendor.Address}, ${vendor.City}, ${vendor.State}`}</span>
          </div>
          <div className="flex items-center gap-2 text-gray-600">
            <Phone className="w-4 h-4" />
            <span className="text-sm">{vendor.PhoneNumber}</span>
          </div>
          <div className="flex items-center gap-2 text-gray-600">
            <Mail className="w-4 h-4" />
            <span className="text-sm">{vendor.EmailAddress}</span>
          </div>
        </div>

        <div className="flex flex-wrap gap-2 mb-4">
          <span className="px-3 py-1 bg-blue-50 text-blue-600 rounded-full text-sm">
            {vendor.VendorSubType}
          </span>
        </div>

        <div className="flex items-center justify-between">
          <div>
            <span className="text-sm font-medium">Contact:</span>
            <span className="text-sm text-gray-600 ml-2">{vendor.ContactName}</span>
          </div>
          <button
            onClick={() => onCompareClick(vendor.ID)}
            className="flex items-center gap-1 text-blue-600 hover:text-blue-700"
          >
            <span className="text-sm font-medium">Compare</span>
            <ArrowRight className="w-4 h-4" />
          </button>
        </div>
      </div>
    </div>
  );
}