import React, { useState } from 'react';
import { Newspaper, Search, Tag } from 'lucide-react';
import ArticleGrid from '../components/ArticleGrid';
import ArticleFilters from '../components/ArticleFilters';

export default function Research() {
  const [filters, setFilters] = useState({
    category: '',
    tags: [] as string[],
    searchQuery: ''
  });

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex items-center gap-3 mb-8">
        <Newspaper className="w-8 h-8 text-blue-600" />
        <h1 className="text-3xl font-bold">Industry Research & Insights</h1>
      </div>

      <div className="flex flex-col lg:flex-row gap-8">
        <aside className="lg:w-80">
          <div className="bg-white rounded-xl shadow-lg p-6 sticky top-8">
            <div className="flex items-center gap-2 mb-6">
              <Tag className="w-5 h-5 text-blue-600" />
              <h2 className="text-xl font-semibold">Filters</h2>
            </div>
            <ArticleFilters filters={filters} onChange={setFilters} />
          </div>
        </aside>

        <main className="flex-1">
          <div className="mb-6">
            <div className="relative">
              <Search className="absolute left-3 top-3 w-5 h-5 text-gray-400" />
              <input
                type="text"
                placeholder="Search articles..."
                value={filters.searchQuery}
                onChange={(e) => setFilters({ ...filters, searchQuery: e.target.value })}
                className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
          </div>
          <ArticleGrid filters={filters} />
        </main>
      </div>
    </div>
  );
}