import React from 'react';
import { Settings, User, Bell, Shield } from 'lucide-react';

export default function AccountSettings() {
  const settings = [
    {
      icon: <User className="w-4 h-4" />,
      label: 'Profile Settings',
      description: 'Update your personal information'
    },
    {
      icon: <Bell className="w-4 h-4" />,
      label: 'Notification Preferences',
      description: 'Manage your alert settings'
    },
    {
      icon: <Shield className="w-4 h-4" />,
      label: 'Security Settings',
      description: 'Update password and security options'
    }
  ];

  return (
    <div className="bg-white rounded-xl shadow-lg p-6">
      <div className="flex items-center gap-2 mb-6">
        <Settings className="w-6 h-6 text-blue-600" />
        <h2 className="text-xl font-semibold">Account Settings</h2>
      </div>

      <div className="space-y-4">
        {settings.map((setting) => (
          <button
            key={setting.label}
            className="w-full p-4 bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors text-left"
          >
            <div className="flex items-center gap-3">
              <div className="text-blue-600">
                {setting.icon}
              </div>
              <div>
                <p className="font-medium">{setting.label}</p>
                <p className="text-sm text-gray-600">
                  {setting.description}
                </p>
              </div>
            </div>
          </button>
        ))}
      </div>
    </div>
  );
}