import React from 'react';
import { HelpCircle } from 'lucide-react';
import { ValuationData, ValidationErrors } from '../../../types/valuation';
import { Tooltip } from '../../ui/Tooltip';

interface PropertyDetailsProps {
  data: ValuationData;
  onUpdate: (data: Partial<ValuationData>) => void;
  errors?: ValidationErrors;
}

export default function PropertyDetails({ data, onUpdate, errors }: PropertyDetailsProps) {
  return (
    <div className="space-y-6">
      <div>
        <div className="flex items-center gap-2 mb-2">
          <label className="block text-sm font-medium text-gray-700">
            Property Size (sq ft)
          </label>
          <Tooltip content="Total building size including store and service areas">
            <HelpCircle className="w-4 h-4 text-gray-400" />
          </Tooltip>
        </div>
        <input
          type="number"
          value={data.propertySize || ''}
          onChange={(e) => onUpdate({ propertySize: Number(e.target.value) })}
          className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          placeholder="e.g., 2500"
        />
      </div>

      <div>
        <div className="flex items-center gap-2 mb-2">
          <label className="block text-sm font-medium text-gray-700">
            Lot Size (sq ft)
          </label>
          <Tooltip content="Total lot size including all paved areas and landscaping">
            <HelpCircle className="w-4 h-4 text-gray-400" />
          </Tooltip>
        </div>
        <input
          type="number"
          value={data.lotSize || ''}
          onChange={(e) => onUpdate({ lotSize: Number(e.target.value) })}
          className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          placeholder="e.g., 20000"
        />
      </div>

      <div>
        <div className="flex items-center gap-2 mb-2">
          <label className="block text-sm font-medium text-gray-700">
            Year Built
          </label>
          <Tooltip content="Original construction year of the property">
            <HelpCircle className="w-4 h-4 text-gray-400" />
          </Tooltip>
        </div>
        <input
          type="number"
          value={data.yearBuilt || ''}
          onChange={(e) => onUpdate({ yearBuilt: Number(e.target.value) })}
          className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          placeholder="e.g., 1995"
        />
      </div>

      <div>
        <div className="flex items-center gap-2 mb-2">
          <label className="block text-sm font-medium text-gray-700">
            Last Major Renovation
          </label>
          <Tooltip content="Year of the last significant property renovation (if applicable)">
            <HelpCircle className="w-4 h-4 text-gray-400" />
          </Tooltip>
        </div>
        <input
          type="number"
          value={data.lastRenovated || ''}
          onChange={(e) => onUpdate({ lastRenovated: Number(e.target.value) })}
          className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          placeholder="Leave blank if none"
        />
      </div>

      <div>
        <div className="flex items-center gap-2 mb-2">
          <label className="block text-sm font-medium text-gray-700">
            Zoning Type
          </label>
          <Tooltip content="Current zoning classification of the property">
            <HelpCircle className="w-4 h-4 text-gray-400" />
          </Tooltip>
        </div>
        <select
          value={data.zoningType}
          onChange={(e) => onUpdate({ zoningType: e.target.value })}
          className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
        >
          <option value="">Select zoning type</option>
          <option value="commercial">Commercial</option>
          <option value="industrial">Industrial</option>
          <option value="mixed-use">Mixed-Use</option>
          <option value="special-use">Special Use</option>
        </select>
      </div>

      <div>
        <div className="flex items-center gap-2 mb-2">
          <label className="block text-sm font-medium text-gray-700">
            Daily Traffic Count
          </label>
          <Tooltip content="Average daily vehicle count passing the location">
            <HelpCircle className="w-4 h-4 text-gray-400" />
          </Tooltip>
        </div>
        <input
          type="number"
          value={data.trafficCount || ''}
          onChange={(e) => onUpdate({ trafficCount: Number(e.target.value) })}
          className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          placeholder="Leave blank if unknown"
        />
      </div>
    </div>
  );
}