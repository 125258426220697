import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend
} from 'chart.js';
import { Card } from '../ui/Card';
import { Fuel, ShoppingBag, Car } from 'lucide-react';
import { cn } from '../../lib/utils';

// Register ChartJS components
ChartJS.register(ArcElement, Tooltip, Legend);

export default function RevenueBreakdown() {
  const data = {
    labels: ['Fuel Sales', 'Store Revenue', 'Car Wash'],
    datasets: [
      {
        data: [65, 25, 10],
        backgroundColor: [
          'rgb(37, 99, 235)',
          'rgb(16, 185, 129)',
          'rgb(245, 158, 11)'
        ]
      }
    ]
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'bottom' as const,
        labels: {
          usePointStyle: true,
          padding: 20
        }
      },
      tooltip: {
        callbacks: {
          label: function(context: any) {
            const label = context.label || '';
            const value = context.raw || 0;
            return `${label}: ${value}%`;
          }
        }
      }
    },
    cutout: '70%'
  };

  const sources = [
    {
      label: 'Fuel Sales',
      value: '$650,000',
      change: '+8.5%',
      icon: <Fuel className="w-5 h-5" />,
      color: 'text-blue-600'
    },
    {
      label: 'Store Revenue',
      value: '$250,000',
      change: '+12.3%',
      icon: <ShoppingBag className="w-5 h-5" />,
      color: 'text-green-600'
    },
    {
      label: 'Car Wash',
      value: '$100,000',
      change: '+5.2%',
      icon: <Car className="w-5 h-5" />,
      color: 'text-amber-600'
    }
  ];

  return (
    <Card>
      <Card.Header>
        <Card.Title>Revenue Breakdown</Card.Title>
      </Card.Header>
      <Card.Content>
        <div className="h-[300px] relative">
          <Doughnut data={data} options={options} />
        </div>
        <div className="grid grid-cols-3 gap-4 mt-8">
          {sources.map((source) => (
            <div
              key={source.label}
              className="flex flex-col items-center p-4 rounded-lg bg-gray-50"
            >
              <div className={cn("p-2 rounded-full mb-2", source.color)}>
                {source.icon}
              </div>
              <span className="text-sm font-medium text-gray-600">{source.label}</span>
              <span className="text-lg font-semibold">{source.value}</span>
              <span className={cn(
                "text-sm",
                source.change.startsWith('+') ? "text-green-600" : "text-red-600"
              )}>
                {source.change}
              </span>
            </div>
          ))}
        </div>
      </Card.Content>
    </Card>
  );
}