import React, { useEffect, useRef } from 'react';
import { PropertyType } from '../../types/property';

interface PropertyMapProps {
  properties: PropertyType[];
  selectedProperty: PropertyType | null;
  onPropertySelect: (property: PropertyType) => void;
  apiKey: string;
}

declare global {
  interface Window {
    google: typeof google;
    initMap: () => void;
    googleMapsLoaded: boolean;
  }
}

export default function PropertyMap({ properties, selectedProperty, onPropertySelect, apiKey }: PropertyMapProps) {
  const mapRef = useRef<HTMLDivElement>(null);
  const mapInstanceRef = useRef<google.maps.Map | null>(null);
  const markersRef = useRef<google.maps.Marker[]>([]);
  const loadingRef = useRef<boolean>(false);

  useEffect(() => {
    const initializeMap = () => {
      if (!mapRef.current || !window.google) return;

      const bounds = new google.maps.LatLngBounds();
      properties.forEach(property => {
        bounds.extend({
          lat: property.coordinates.lat,
          lng: property.coordinates.lng
        });
      });

      const map = new google.maps.Map(mapRef.current, {
        zoom: 10,
        mapTypeControl: true,
        streetViewControl: true,
        fullscreenControl: true,
      });

      map.fitBounds(bounds);
      mapInstanceRef.current = map;

      properties.forEach(property => {
        const marker = new google.maps.Marker({
          position: {
            lat: property.coordinates.lat,
            lng: property.coordinates.lng
          },
          map,
          title: property.title,
          animation: google.maps.Animation.DROP,
          icon: {
            path: google.maps.SymbolPath.CIRCLE,
            scale: 10,
            fillColor: selectedProperty?.id === property.id ? '#2563eb' : '#60a5fa',
            fillOpacity: 0.7,
            strokeColor: 'white',
            strokeWeight: 2,
          }
        });

        const infoWindow = new google.maps.InfoWindow({
          content: `
            <div class="p-2">
              <h3 class="font-semibold">${property.title}</h3>
              <p class="text-gray-600">${property.location}</p>
              <p class="font-bold text-blue-600">$${(property.price / 1000000).toFixed(1)}M</p>
            </div>
          `
        });

        marker.addListener('click', () => {
          onPropertySelect(property);
          infoWindow.open(map, marker);
        });

        markersRef.current.push(marker);
      });
    };

    const loadGoogleMaps = () => {
      if (window.google || loadingRef.current) return;

      loadingRef.current = true;
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}`;
      script.async = true;
      script.defer = true;
      script.onload = () => {
        window.googleMapsLoaded = true;
        loadingRef.current = false;
        initializeMap();
      };
      document.head.appendChild(script);
    };

    if (window.google) {
      initializeMap();
    } else {
      loadGoogleMaps();
    }

    return () => {
      markersRef.current.forEach(marker => marker.setMap(null));
      markersRef.current = [];
    };
  }, [properties, apiKey, selectedProperty, onPropertySelect]);

  useEffect(() => {
    if (!mapInstanceRef.current || !window.google) return;

    markersRef.current.forEach(marker => {
      const property = properties.find(p => {
        const position = marker.getPosition();
        return position && 
          p.coordinates.lat === position.lat() &&
          p.coordinates.lng === position.lng();
      });

      if (property) {
        marker.setIcon({
          path: google.maps.SymbolPath.CIRCLE,
          scale: 10,
          fillColor: selectedProperty?.id === property.id ? '#2563eb' : '#60a5fa',
          fillOpacity: 0.7,
          strokeColor: 'white',
          strokeWeight: 2,
        });
      }
    });
  }, [selectedProperty, properties]);

  return (
    <div ref={mapRef} className="w-full h-full min-h-[400px] rounded-lg" />
  );
}