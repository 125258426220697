import React, { createContext, useContext, useState, useEffect } from 'react';

export type SubscriptionTier = 'free' | 'pro' | 'enterprise';

interface SubscriptionContextType {
  tier: SubscriptionTier;
  setTier: (tier: SubscriptionTier) => void;
  isProOrHigher: () => boolean;
  isEnterprise: () => boolean;
}

const SubscriptionContext = createContext<SubscriptionContextType | undefined>(undefined);

export function SubscriptionProvider({ children }: { children: React.ReactNode }) {
  const [tier, setTier] = useState<SubscriptionTier>('free');

  // Load subscription tier from localStorage on mount
  useEffect(() => {
    const savedTier = localStorage.getItem('subscription_tier');
    if (savedTier && ['free', 'pro', 'enterprise'].includes(savedTier)) {
      setTier(savedTier as SubscriptionTier);
    }
  }, []);

  // Save subscription tier to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem('subscription_tier', tier);
  }, [tier]);

  const isProOrHigher = () => tier === 'pro' || tier === 'enterprise';
  const isEnterprise = () => tier === 'enterprise';

  return (
    <SubscriptionContext.Provider value={{ tier, setTier, isProOrHigher, isEnterprise }}>
      {children}
    </SubscriptionContext.Provider>
  );
}

export function useSubscription() {
  const context = useContext(SubscriptionContext);
  if (context === undefined) {
    throw new Error('useSubscription must be used within a SubscriptionProvider');
  }
  return context;
}
