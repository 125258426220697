import React from 'react';
import * as SliderPrimitive from '@radix-ui/react-slider';
import { cn } from '../../lib/utils';

interface SliderProps {
  min: number;
  max: number;
  step: number;
  value: number[];
  onChange: (value: number[]) => void;
  formatLabel?: (value: number) => string;
}

export function Slider({ min, max, step, value, onChange, formatLabel }: SliderProps) {
  return (
    <div className="space-y-4">
      <SliderPrimitive.Root
        className="relative flex items-center select-none touch-none w-full h-5"
        value={value}
        onValueChange={onChange}
        min={min}
        max={max}
        step={step}
      >
        <SliderPrimitive.Track className="bg-gray-200 relative grow rounded-full h-2">
          <SliderPrimitive.Range className="absolute bg-blue-600 rounded-full h-full" />
        </SliderPrimitive.Track>
        {value.map((_, index) => (
          <SliderPrimitive.Thumb
            key={index}
            className={cn(
              "block w-5 h-5 bg-white rounded-full border-2 border-blue-600",
              "focus:outline-none focus-visible:ring focus-visible:ring-blue-500",
              "hover:bg-blue-50 transition-colors"
            )}
          />
        ))}
      </SliderPrimitive.Root>
      <div className="flex justify-between text-sm text-gray-600">
        <span>{formatLabel ? formatLabel(value[0]) : value[0]}</span>
        <span>{formatLabel ? formatLabel(value[1]) : value[1]}</span>
      </div>
    </div>
  );
}