import { createClient } from '@supabase/supabase-js';
import dotenv from 'dotenv';
import type { Database } from './database.types';

// Load environment variables in Node.js environment
if (typeof process !== 'undefined') {
  dotenv.config();
}

// Get environment variables from either Vite or Node.js environment
const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
const supabaseAnonKey = import.meta.env.VITE_SUPABASE_ANON_KEY;

if (!supabaseUrl || !supabaseAnonKey) {
  throw new Error('Missing Supabase environment variables');
}

export const supabase = createClient<Database>(supabaseUrl, supabaseAnonKey, {
  auth: {
    persistSession: true,
    autoRefreshToken: true,
    detectSessionInUrl: true
  }
});

// Create admin client only in Node.js environment (for scripts)
export const createAdminClient = () => {
  if (typeof process !== 'undefined' && process.env.VITE_SUPABASE_SERVICE_KEY) {
    return createClient(
      process.env.VITE_SUPABASE_URL || supabaseUrl,
      process.env.VITE_SUPABASE_SERVICE_KEY
    );
  }
  return null;
};

export const signInWithEmail = async (email: string, password: string) => {
  const { data, error } = await supabase.auth.signInWithPassword({
    email,
    password,
  });

  if (error) throw error;
  
  // Log the session data to verify token
  console.log('Session data:', data.session);
  
  return { data, error };
};

export const signUpWithEmail = async (email: string, password: string, metadata?: { [key: string]: any }) => {
  const response = await supabase.auth.signUp({
    email,
    password,
    options: {
      data: metadata,
    },
  });

  if (response.error) throw response.error;
  return response;
};

export const signInWithGoogle = async () => {
  const response = await supabase.auth.signInWithOAuth({
    provider: 'google',
    options: {
      queryParams: {
        access_type: 'offline',
        prompt: 'consent',
      },
      redirectTo: `${window.location.origin}/auth/callback`
    }
  });

  if (response.error) throw response.error;
  return response;
};

export const signOut = async () => {
  const { error } = await supabase.auth.signOut();
  if (error) throw error;
};