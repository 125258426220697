import { create } from 'zustand';
import { User } from '@supabase/supabase-js';
import { Database } from '../lib/database.types';

type Profile = Database['public']['Tables']['profiles']['Row'];
export type SubscriptionTier = 'free' | 'pro' | 'enterprise';

interface AuthState {
  isAuthenticated: boolean;
  user: Profile | null;
  loading: boolean;
  error: string | null;
  token: string | null;
  setUser: (user: Profile, token: string) => void;
  clearUser: () => void;
  clearError: () => void;
  logout: () => void;
}

export const useAuthStore = create<AuthState>((set) => ({
  isAuthenticated: false,
  user: null,
  loading: false,
  error: null,
  token: null,

  setUser: (user, token) => set({
    isAuthenticated: true,
    user,
    loading: false,
    error: null,
    token
  }),

  clearUser: () => set({
    isAuthenticated: false,
    user: null,
    loading: false,
    error: null,
    token: null
  }),

  clearError: () => set({ error: null }),

  logout: () => set({
    isAuthenticated: false,
    user: null,
    loading: false,
    error: null,
    token: null
  })
}));