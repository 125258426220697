import { Fragment } from 'react';
import { Check, Minus } from 'lucide-react';
import { Vendor } from '../../pages/VendorList';
import { Modal } from '../ui/Modal';

interface VendorComparisonProps {
  vendors: Vendor[];
  onClose: () => void;
}

export default function VendorComparison({ vendors, onClose }: VendorComparisonProps) {
  const comparisonCategories = [
    { label: 'Basic Information', items: [
      { key: 'VendorName', label: 'Name' },
      { key: 'VendorType', label: 'Type' },
      { key: 'VendorSubType', label: 'Sub Type' },
      { key: 'ContactName', label: 'Contact Name' }
    ]},
    { label: 'Contact Information', items: [
      { key: 'EmailAddress', label: 'Email' },
      { key: 'PhoneNumber', label: 'Phone' },
      { key: 'Website', label: 'Website' }
    ]},
    { label: 'Location', items: [
      { key: 'Address', label: 'Address' },
      { key: 'City', label: 'City' },
      { key: 'State', label: 'State' },
      { key: 'ZipCode', label: 'Zip Code' }
    ]}
  ];

  const renderValue = (vendor: Vendor, key: keyof Vendor) => {
    const value = vendor[key];
    
    if (value === null) {
      return <span className="text-gray-400">Not provided</span>;
    }

    return String(value);
  };

  return (
    <Modal
      isOpen={true}
      onClose={onClose}
      title="Vendor Comparison"
    >
      <div className="max-h-[70vh] overflow-y-auto">
        <table className="w-full">
          <thead className="bg-gray-50 sticky top-0">
            <tr>
              <th className="p-4 text-left text-gray-600 font-medium">
                Features
              </th>
              {vendors.map((vendor) => (
                <th key={vendor.ID} className="p-4 text-left text-gray-600 font-medium">
                  {vendor.VendorName}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {comparisonCategories.map((category) => (
              <Fragment key={category.label}>
                <tr className="bg-gray-50">
                  <td
                    colSpan={vendors.length + 1}
                    className="p-4 font-semibold text-gray-700"
                  >
                    {category.label}
                  </td>
                </tr>
                {category.items.map((item) => (
                  <tr key={item.key} className="hover:bg-gray-50">
                    <td className="p-4 text-gray-600">
                      {item.label}
                    </td>
                    {vendors.map((vendor) => (
                      <td key={vendor.ID} className="p-4">
                        {renderValue(vendor, item.key as keyof Vendor)}
                      </td>
                    ))}
                  </tr>
                ))}
              </Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </Modal>
  );
}