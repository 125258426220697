import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Building2, DollarSign, MapPin } from 'lucide-react';
import ServiceCard from '../components/ServiceCard';
import NewsGrid from '../components/NewsGrid';

export default function Home() {
  const navigate = useNavigate();

  const services = [
    {
      title: "What's My Property Worth?",
      description: "Get an accurate valuation for your gas station property based on market data and industry metrics.",
      icon: <Building2 className="w-12 h-12" />,
      path: '/valuation'
    },
    {
      title: "Marketplace",
      description: "Browse available gas station properties and connect with sellers in your target market.",
      icon: <DollarSign className="w-12 h-12" />,
      path: '/properties'
    },
    {
      title: "Where Should I Build?",
      description: "Analyze potential locations and get detailed feasibility studies for new developments.",
      icon: <MapPin className="w-12 h-12" />,
      path: '/location-analysis'
    }
  ];

  return (
    <div className="w-full">
      {/* Hero Section */}
      <div className="bg-gradient-to-br from-blue-600 to-blue-800 text-white">
        <div className="container mx-auto px-4 py-20">
          <div className="max-w-3xl">
            <h1 className="text-5xl font-bold mb-6">Make Informed Decisions About Your Gas Station Property</h1>
            <p className="text-xl mb-8 text-blue-100">Get accurate valuations, market insights, and location analysis tools trusted by industry professionals.</p>
          </div>
        </div>
      </div>

      {/* Services Grid */}
      <div className="container mx-auto px-4 -mt-16 relative z-10">
        <div className="grid md:grid-cols-3 gap-8">
          {services.map((service) => (
            <ServiceCard
              key={service.title}
              title={service.title}
              description={service.description}
              icon={service.icon}
              onClick={() => navigate(service.path)}
            />
          ))}
        </div>
      </div>

      {/* News Section */}
      <div className="container mx-auto px-4 py-20">
        <div className="flex items-center justify-between mb-8">
          <h2 className="text-2xl font-bold">Latest Industry Insights</h2>
          <button 
            onClick={() => navigate('/research')}
            className="text-blue-600 font-semibold hover:text-blue-700"
          >
            View All Articles
          </button>
        </div>
        <NewsGrid />
      </div>
    </div>
  );
}