import React from 'react';
import { Building2 } from 'lucide-react';
import ValuationWizard from '../components/valuation/ValuationWizard';

export default function Valuation() {
  return (
    <div className="container mx-auto px-4 py-8">
      <div className="max-w-3xl mx-auto">
        <div className="flex items-center gap-3 mb-8">
          <Building2 className="w-8 h-8 text-blue-600" />
          <div>
            <h1 className="text-3xl font-bold mb-2">Gas Station Valuation</h1>
            <p className="text-gray-600">
              Get an accurate valuation for your gas station property based on comprehensive market data
            </p>
          </div>
        </div>

        <ValuationWizard />
      </div>
    </div>
  );
}