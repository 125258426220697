import React, { useState } from 'react';
import './SubscriptionTier.css';
import { FaUser, FaUserPlus, FaUsers } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const SubscriptionTier = () => {
    const [selectedTier, setSelectedTier] = useState('');
    const navigate = useNavigate();

    const handleSelectTier = (tier: string) => {
        setSelectedTier(tier);
    };

    const handleContinue = () => {
        if (selectedTier) {
            navigate('/signup');
        }
    };

    return (
        <div className="subscription-tier-container">
            <img src="https://tminurcnztragojlogjv.supabase.co/storage/v1/object/sign/Stock%20Photos/Pump%20Logo%20Final%20SVG.svg?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJTdG9jayBQaG90b3MvUHVtcCBMb2dvIEZpbmFsIFNWRy5zdmciLCJpYXQiOjE3MzE2NDE0MjAsImV4cCI6MTc2MzE3NzQyMH0.pZPWfHvrxsMGdAdCYVTveaBAOFXPhpi-gfmh2gu5RhE&t=2024-11-15T03%3A30%3A20.070Z" alt="Company Logo" className="company-logo" />
            <h1>Select Your Subscription Tier</h1>
            <div className="tier-options">
                <div className={`tier free ${selectedTier === 'Free' ? 'selected' : ''}`} onClick={() => handleSelectTier('Free')}>
                    <FaUser size={50} color="#007bff" />
                    <h2>Free</h2>
                    <ul>
                        <li>Access to basic features</li>
                        <li>Limited support</li>
                        <li>Community access</li>
                        <li>Basic analytics</li>
                    </ul>
                    <p>Pricing: $0/month</p>
                </div>
                <div className={`tier pro ${selectedTier === 'Pro' ? 'selected' : ''}`} onClick={() => handleSelectTier('Pro')}>
                    <FaUserPlus size={50} color="#28a745" />
                    <h2>Pro</h2>
                    <ul>
                        <li>Advanced features</li>
                        <li>Priority support</li>
                        <li>Monthly reports</li>
                        <li>Customizable dashboards</li>
                        <li>Integration with third-party tools</li>
                    </ul>
                    <p>Pricing: $19/month</p>
                </div>
                <div className={`tier enterprise ${selectedTier === 'Enterprise' ? 'selected' : ''}`} onClick={() => handleSelectTier('Enterprise')}>
                    <FaUsers size={50} color="#ffc107" />
                    <h2>Enterprise</h2>
                    <ul>
                        <li>Custom solutions</li>
                        <li>Dedicated support</li>
                        <li>Quarterly strategy sessions</li>
                        <li>Unlimited user accounts</li>
                        <li>24/7 dedicated account manager</li>
                    </ul>
                    <p>Pricing: Contact us for pricing</p>
                </div>
            </div>
            <button className="continue-button" onClick={handleContinue} disabled={!selectedTier}>Continue to Sign Up</button>
        </div>
    );
};

export default SubscriptionTier;
