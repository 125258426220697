import React from 'react';

export default function ContactSales() {
  return (
    <div className="container mx-auto px-4 py-16">
      <h1 className="text-4xl font-bold mb-4">Contact Sales</h1>
      <p className="text-xl text-gray-600">Get in touch with our sales team for enterprise solutions.</p>
      {/* Add contact form or details here */}
    </div>
  );
}
