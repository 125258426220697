import React from 'react';
import { Car, Users, Building2, Fuel } from 'lucide-react';

interface LocationMetricsProps {
  metrics: {
    trafficCount: number;
    competitors: number;
    population: number;
    medianIncome: number;
    fuelTerminals: number;
  };
}

export default function LocationMetrics({ metrics }: LocationMetricsProps) {
  const metricCards = [
    {
      icon: <Car className="w-6 h-6" />,
      label: 'Daily Traffic',
      value: `${(metrics.trafficCount / 1000).toFixed(1)}K`,
      subtext: 'vehicles per day'
    },
    {
      icon: <Building2 className="w-6 h-6" />,
      label: 'Competitors',
      value: metrics.competitors,
      subtext: 'within radius'
    },
    {
      icon: <Users className="w-6 h-6" />,
      label: 'Population',
      value: `${(metrics.population / 1000).toFixed(1)}K`,
      subtext: 'in trade area'
    },
    {
      icon: <Fuel className="w-6 h-6" />,
      label: 'Fuel Terminals',
      value: metrics.fuelTerminals,
      subtext: 'nearby'
    }
  ];

  return (
    <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-4">
      {metricCards.map((metric) => (
        <div
          key={metric.label}
          className="bg-white rounded-xl shadow-lg p-6"
        >
          <div className="text-blue-600 mb-4">{metric.icon}</div>
          <div className="space-y-1">
            <p className="text-sm text-gray-600">{metric.label}</p>
            <p className="text-2xl font-bold">{metric.value}</p>
            <p className="text-xs text-gray-500">{metric.subtext}</p>
          </div>
        </div>
      ))}
    </div>
  );
}