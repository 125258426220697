import { useEffect, useRef } from 'react';
import Map from 'ol/Map';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import { fromLonLat, transformExtent } from 'ol/proj';
import { Circle as CircleStyle, Fill, Stroke, Style } from 'ol/style';
import { supabase } from '../lib/supabase';
import { Database } from '../lib/database.types';
import 'ol/ol.css';

type GasStation = Database['public']['Tables']['gas_stations_ca']['Row']

type Location = {
  lat: number;
  lng: number;
  address: string;
};

interface LocationMapProps {
  selectedLocation: Location;
  onLocationChange: (location: Location) => void;
  radius: number;
}

export default function LocationMap({ selectedLocation, onLocationChange, radius }: LocationMapProps) {
  const mapRef = useRef<HTMLDivElement>(null);
  const mapInstanceRef = useRef<Map | null>(null);
  const vectorSourceRef = useRef<VectorSource | null>(null);
  const isLoadingRef = useRef<boolean>(false);
  const lastExtentRef = useRef<number[]>();

  useEffect(() => {
    if (!mapRef.current) return;

    // Initialize map
    const vectorSource = new VectorSource();
    vectorSourceRef.current = vectorSource;

    const vectorLayer = new VectorLayer({
      source: vectorSource,
      style: new Style({
        image: new CircleStyle({
          radius: 6,
          fill: new Fill({ color: '#3b82f6' }),
          stroke: new Stroke({ color: '#1d4ed8', width: 2 }),
        }),
      }),
    });

    const map = new Map({
      target: mapRef.current,
      layers: [
        new TileLayer({
          source: new OSM(),
        }),
        vectorLayer,
      ],
      view: new View({
        center: fromLonLat([-119.4179, 36.7783]), // Center of California
        zoom: 6,
        minZoom: 6, // Restrict minimum zoom level
        maxZoom: 18,
        extent: transformExtent(
          [-124.4, 32.5, -114.1, 42.0], // California bounds
          'EPSG:4326',
          'EPSG:3857'
        ),
      }),
    });

    mapInstanceRef.current = map;

    // Add event listener for map movement
    map.on('moveend', async () => {
      await loadStationsInView();
    });

    // Add event listener for map zoom
    const view = map.getView();
    view.on('change:resolution', async () => {
      await loadStationsInView();
    });

    // Initial load
    loadStationsInView();

    return () => {
      map.setTarget(undefined);
    };
  }, []);

  const loadStationsInView = async () => {
    if (!mapInstanceRef.current || !vectorSourceRef.current || isLoadingRef.current) return;

    try {
      isLoadingRef.current = true;
      const view = mapInstanceRef.current.getView();
      const zoom = view.getZoom();
      
      // Don't load stations at low zoom levels
      if (zoom && zoom < 8) {
        vectorSourceRef.current.clear();
        console.log('Zoom level too low for loading stations:', zoom);
        return;
      }

      const extent = view.calculateExtent();
      const [minLon, minLat, maxLon, maxLat] = transformExtent(extent, 'EPSG:3857', 'EPSG:4326');

      // Ensure coordinates are in correct order and within California bounds
      const queryMinLat = Math.max(32.5, Math.min(minLat, maxLat));
      const queryMaxLat = Math.min(42.0, Math.max(minLat, maxLat));
      const queryMinLon = Math.max(-124.4, Math.min(minLon, maxLon));
      const queryMaxLon = Math.min(-114.1, Math.max(minLon, maxLon));

      console.log(`Querying stations in viewport:
        Latitude: ${queryMinLat.toFixed(4)}°N to ${queryMaxLat.toFixed(4)}°N
        Longitude: ${queryMinLon.toFixed(4)}°W to ${queryMaxLon.toFixed(4)}°W
        Zoom Level: ${zoom}`);

      // Clear existing features
      vectorSourceRef.current.clear();

      // Build query based on zoom level
      let query = supabase
        .from('gas_stations_ca')
        .select('*')
        .not('latitude', 'is', null)
        .not('longitude', 'is', null)
        .gte('latitude', queryMinLat)
        .lte('latitude', queryMaxLat)
        .gte('longitude', queryMinLon)
        .lte('longitude', queryMaxLon);

      // Adjust limit based on zoom level
      const limit = zoom ? Math.min(Math.pow(2, zoom) * 10, 500) : 500;
      query = query.limit(limit);

      const { data: stations, error } = await query;

      if (error) {
        console.error('Error fetching gas stations:', error);
        return;
      }

      console.log(`Found ${stations.length} stations in current viewport (limit: ${limit})`);

      // Add features for valid stations
      stations.forEach((station) => {
        if (station.latitude && station.longitude &&
            station.latitude !== 0 && station.longitude !== 0) {
          const feature = new Feature({
            geometry: new Point(fromLonLat([station.longitude, station.latitude])),
            properties: {
              id: station.OBJECTID,
              address: station.Facility_Address,
              city: station.City,
              state: station.STATE,
              zipCode: station.ZIP_Code,
            },
          });
          feature.setId(station.OBJECTID);
          vectorSourceRef.current?.addFeature(feature);
        }
      });

    } catch (error) {
      console.error('Error loading stations:', error);
    } finally {
      isLoadingRef.current = false;
    }
  };

  return (
    <div 
      ref={mapRef} 
      className="w-full h-[600px] rounded-lg shadow-lg border border-gray-200"
    />
  );
}