import React, { useState } from 'react';
import { MapPin, Ruler, Car, Users, DollarSign } from 'lucide-react';
import LocationMap from '../components/LocationMap';
import LocationMetrics from '../components/LocationMetrics';
import CompetitorAnalysis from '../components/CompetitorAnalysis';
import DemographicData from '../components/DemographicData';

export default function LocationAnalysis() {
  const [selectedLocation, setSelectedLocation] = useState({
    lat: 34.0522,
    lng: -118.2437,
    address: 'Los Angeles, CA'
  });

  const [radius, setRadius] = useState(3); // miles

  const metrics = {
    trafficCount: 45000,
    competitors: 3,
    population: 125000,
    medianIncome: 75000,
    fuelTerminals: 2
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex items-center gap-3 mb-8">
        <MapPin className="w-8 h-8 text-blue-600" />
        <h1 className="text-3xl font-bold">Location Analysis</h1>
      </div>

      <div className="grid lg:grid-cols-3 gap-8">
        {/* Map and Controls */}
        <div className="lg:col-span-2 space-y-6">
          <div className="bg-white rounded-xl shadow-lg p-6">
            <LocationMap
              selectedLocation={selectedLocation}
              onLocationChange={setSelectedLocation}
              radius={radius}
            />
            <div className="mt-4 flex items-center gap-4">
              <div className="flex-1">
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Analysis Radius (miles)
                </label>
                <select
                  value={radius}
                  onChange={(e) => setRadius(Number(e.target.value))}
                  className="w-full p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                >
                  <option value={1}>1 mile</option>
                  <option value={3}>3 miles</option>
                  <option value={5}>5 miles</option>
                </select>
              </div>
              <div className="flex-1">
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Selected Location
                </label>
                <div className="text-gray-600 truncate">
                  {selectedLocation.address}
                </div>
              </div>
            </div>
          </div>

          <LocationMetrics metrics={metrics} />
        </div>

        {/* Analysis Sidebar */}
        <div className="space-y-6">
          <CompetitorAnalysis competitors={metrics.competitors} />
          <DemographicData
            population={metrics.population}
            medianIncome={metrics.medianIncome}
          />
        </div>
      </div>
    </div>
  );
}