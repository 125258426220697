import React from 'react';
import { Clock, TrendingUp } from 'lucide-react';

export default function RecentValuations() {
  const valuations = [
    {
      id: '1',
      propertyName: 'Los Angeles Gas Station',
      date: '2024-03-15',
      estimatedValue: {
        low: 2500000,
        high: 3000000
      },
      metrics: {
        monthlyGallons: 150000,
        storeRevenue: 75000
      }
    },
    {
      id: '2',
      propertyName: 'Sacramento Highway Station',
      date: '2024-03-10',
      estimatedValue: {
        low: 3500000,
        high: 4000000
      },
      metrics: {
        monthlyGallons: 200000,
        storeRevenue: 100000
      }
    }
  ];

  return (
    <div className="bg-white rounded-xl shadow-lg p-6">
      <div className="flex items-center gap-2 mb-6">
        <Clock className="w-6 h-6 text-blue-600" />
        <h2 className="text-xl font-semibold">Recent Valuations</h2>
      </div>

      <div className="space-y-4">
        {valuations.map((valuation) => (
          <div
            key={valuation.id}
            className="p-4 bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors"
          >
            <div className="flex justify-between items-start mb-3">
              <div>
                <h3 className="font-semibold">{valuation.propertyName}</h3>
                <p className="text-sm text-gray-600">
                  {new Date(valuation.date).toLocaleDateString()}
                </p>
              </div>
              <div className="text-right">
                <p className="text-sm text-gray-600">Estimated Value</p>
                <p className="font-bold text-blue-600">
                  ${(valuation.estimatedValue.low / 1000000).toFixed(1)}M - 
                  ${(valuation.estimatedValue.high / 1000000).toFixed(1)}M
                </p>
              </div>
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div>
                <p className="text-sm text-gray-600">Monthly Gallons</p>
                <p className="font-semibold">
                  {(valuation.metrics.monthlyGallons / 1000).toFixed(0)}K
                </p>
              </div>
              <div>
                <p className="text-sm text-gray-600">Store Revenue</p>
                <p className="font-semibold">
                  ${(valuation.metrics.storeRevenue / 1000).toFixed(0)}K
                </p>
              </div>
            </div>

            <button className="w-full mt-3 text-blue-600 hover:text-blue-700 text-sm font-semibold">
              View Full Report
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}