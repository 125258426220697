import React, { useEffect, useRef } from 'react';
import { HelpCircle } from 'lucide-react';
import { ValuationData, ValidationErrors } from '../../../types/valuation';
import { Tooltip } from '../../ui/Tooltip';

interface BasicInfoProps {
  data: ValuationData;
  onUpdate: (data: Partial<ValuationData>) => void;
  errors?: ValidationErrors;
}

const fuelBrands = [
  'Chevron',
  'Shell',
  'Arco',
  'Sinclair',
  'BP',
  'Exxon',
  'Mobil',
  'Texaco',
  'Valero',
  'Other'
];

const GOOGLE_MAPS_API_KEY = 'AIzaSyCnMNbV39RcyOFhxsujKbmtWSnxvrS9Mp0';

export default function BasicInfo({ data, onUpdate, errors = {} }: BasicInfoProps) {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    // Load Google Maps JavaScript API
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`;
    script.async = true;
    script.defer = true;
    script.onload = initAutocomplete;
    document.head.appendChild(script);

    return () => {
      // Cleanup
      document.head.removeChild(script);
    };
  }, []);

  const initAutocomplete = () => {
    if (!inputRef.current || !window.google) return;

    const autocomplete = new google.maps.places.Autocomplete(inputRef.current, {
      types: ['address'],
      componentRestrictions: { country: 'us' }
    });

    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();
      if (place.formatted_address) {
        console.log('Selected location:', place.formatted_address);
        onUpdate({ location: place.formatted_address });
      }
    });
  };

  return (
    <div className="space-y-6">
      <div>
        <div className="flex items-center gap-2 mb-2">
          <label className="block text-sm font-medium text-gray-700">
            Property Name
          </label>
          <Tooltip content="Enter the business name or property identifier">
            <HelpCircle className="w-4 h-4 text-gray-400" />
          </Tooltip>
        </div>
        <input
          type="text"
          value={data.propertyName}
          onChange={(e) => onUpdate({ propertyName: e.target.value })}
          className={`w-full p-3 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 ${
            errors.propertyName ? 'border-red-500' : 'border-gray-300'
          }`}
          placeholder="e.g., Main Street Gas & Service"
        />
        {errors.propertyName && (
          <p className="mt-1 text-sm text-red-500">{errors.propertyName}</p>
        )}
      </div>

      <div>
        <div className="flex items-center gap-2 mb-2">
          <label className="block text-sm font-medium text-gray-700">
            Location
          </label>
          <Tooltip content="Enter the full address of the property">
            <HelpCircle className="w-4 h-4 text-gray-400" />
          </Tooltip>
        </div>
        <input
          ref={inputRef}
          type="text"
          value={data.location}
          onChange={(e) => onUpdate({ location: e.target.value })}
          className={`w-full p-3 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 ${
            errors.location ? 'border-red-500' : 'border-gray-300'
          }`}
          placeholder="Enter property address"
        />
        {errors.location && (
          <p className="mt-1 text-sm text-red-500">{errors.location}</p>
        )}
      </div>

      <div>
        <div className="flex items-center gap-2 mb-2">
          <label className="block text-sm font-medium text-gray-700">
            Property Ownership
          </label>
          <Tooltip content="Select whether the property is owned or leased">
            <HelpCircle className="w-4 h-4 text-gray-400" />
          </Tooltip>
        </div>
        <select
          value={data.ownership}
          onChange={(e) => onUpdate({ ownership: e.target.value as 'owned' | 'leased' })}
          className={`w-full p-3 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 ${
            errors.ownership ? 'border-red-500' : 'border-gray-300'
          }`}
        >
          <option value="">Select ownership type</option>
          <option value="owned">Owned</option>
          <option value="leased">Leased</option>
        </select>
        {errors.ownership && (
          <p className="mt-1 text-sm text-red-500">{errors.ownership}</p>
        )}

        {data.ownership === 'leased' && (
          <div className="mt-4">
            <div className="flex items-center gap-2 mb-2">
              <label className="block text-sm font-medium text-gray-700">
                Lease Term Remaining (Years)
              </label>
              <Tooltip content="Enter the number of years remaining on the current lease">
                <HelpCircle className="w-4 h-4 text-gray-400" />
              </Tooltip>
            </div>
            <input
              type="number"
              value={data.leaseTermYears || ''}
              onChange={(e) => {
                const value = e.target.value;
                const formattedValue = value ? parseInt(value.replace(/,/g, ''), 10) : '';
                onUpdate({ leaseTermYears: formattedValue as number });
              }}
              className={`w-full p-3 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 ${
                errors.leaseTermYears ? 'border-red-500' : 'border-gray-300'
              }`}
              placeholder="Years remaining"
              min="0"
            />
            {errors.leaseTermYears && (
              <p className="mt-1 text-sm text-red-500">{errors.leaseTermYears}</p>
            )}
          </div>
        )}
      </div>

      <div>
        <div className="flex items-center gap-2 mb-2">
          <label className="block text-sm font-medium text-gray-700">
            Brand Affiliation
          </label>
          <Tooltip content="Select whether the station is branded or unbranded">
            <HelpCircle className="w-4 h-4 text-gray-400" />
          </Tooltip>
        </div>
        <select
          value={data.brandAffiliation}
          onChange={(e) => onUpdate({ brandAffiliation: e.target.value as 'branded' | 'unbranded' })}
          className={`w-full p-3 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 ${
            errors.brandAffiliation ? 'border-red-500' : 'border-gray-300'
          }`}
        >
          <option value="">Select brand affiliation</option>
          <option value="branded">Branded</option>
          <option value="unbranded">Unbranded</option>
        </select>
        {errors.brandAffiliation && (
          <p className="mt-1 text-sm text-red-500">{errors.brandAffiliation}</p>
        )}

        {data.brandAffiliation === 'branded' && (
          <>
            <div className="mt-4">
              <div className="flex items-center gap-2 mb-2">
                <label className="block text-sm font-medium text-gray-700">
                  Brand Name
                </label>
                <Tooltip content="Select the fuel brand affiliated with this station">
                  <HelpCircle className="w-4 h-4 text-gray-400" />
                </Tooltip>
              </div>
              <select
                value={data.brandName}
                onChange={(e) => onUpdate({ brandName: e.target.value })}
                className={`w-full p-3 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 ${
                  errors.brandName ? 'border-red-500' : 'border-gray-300'
                }`}
              >
                <option value="">Select brand</option>
                {fuelBrands.map((brand) => (
                  <option key={brand} value={brand}>{brand}</option>
                ))}
              </select>
              {errors.brandName && (
                <p className="mt-1 text-sm text-red-500">{errors.brandName}</p>
              )}
            </div>

            <div className="mt-4">
              <div className="flex items-center gap-2 mb-2">
                <label className="block text-sm font-medium text-gray-700">
                  Fuel Supply Agreement Years Remaining
                </label>
                <Tooltip content="Enter the number of years remaining on the fuel supply agreement">
                  <HelpCircle className="w-4 h-4 text-gray-400" />
                </Tooltip>
              </div>
              <input
                type="number"
                value={data.fuelSupplyYears || ''}
                onChange={(e) => {
                  const value = e.target.value;
                  const formattedValue = value ? parseInt(value.replace(/,/g, ''), 10) : '';
                  onUpdate({ fuelSupplyYears: formattedValue as number });
                }}
                className={`w-full p-3 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 ${
                  errors.fuelSupplyYears ? 'border-red-500' : 'border-gray-300'
                }`}
                placeholder="Years remaining"
                min="0"
              />
              {errors.fuelSupplyYears && (
                <p className="mt-1 text-sm text-red-500">{errors.fuelSupplyYears}</p>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
}