import React from 'react';
import { Building2, DollarSign, Tag } from 'lucide-react';

export default function BannerAd() {
  return (
    <div className="bg-gradient-to-r from-blue-600 to-blue-800 text-white p-8">
      <div className="max-w-7xl mx-auto flex flex-col items-center text-center">
        <div className="flex items-center space-x-4 mb-4">
          <Building2 className="w-10 h-10" />
          <DollarSign className="w-10 h-10" />
          <Tag className="w-10 h-10" />
        </div>
        <h2 className="text-3xl font-bold mb-4">
          Your Advertisement Here
        </h2>
        <p className="text-xl text-blue-100 mb-6 max-w-2xl">
          Reach thousands of gas station owners and investors. 
          Perfect space for industry-related products, services, or announcements.
        </p>
        <div className="grid grid-cols-3 gap-8 w-full max-w-3xl">
          <div className="text-center">
            <div className="text-4xl font-bold mb-2">50K+</div>
            <div className="text-blue-100">Monthly Views</div>
          </div>
          <div className="text-center">
            <div className="text-4xl font-bold mb-2">2K+</div>
            <div className="text-blue-100">Active Users</div>
          </div>
          <div className="text-center">
            <div className="text-4xl font-bold mb-2">95%</div>
            <div className="text-blue-100">Industry Focus</div>
          </div>
        </div>
      </div>
    </div>
  );
}
