import React from 'react';
import { Check, ChevronRight } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../components/ui/Button';
import { Card } from '../components/ui/Card';
import { cn } from '../lib/utils';

export default function Pricing() {
  const navigate = useNavigate();

  const plans = [
    {
      name: 'Pro',
      price: '$199',
      period: 'per month',
      description: 'Perfect for individual operators and small portfolios',
      features: [
        'Full access to Analytics Dashboard',
        'Market Analysis Reports',
        'Operational Performance Insights',
        'Competitor Analysis',
        'Unlimited Property Valuations',
        'Priority Customer Support',
        'Monthly Market Trends Report',
        'Custom Property Alerts'
      ],
      buttonText: 'Get Pro',
      buttonVariant: 'primary' as const,
      highlight: false
    },
    {
      name: 'Enterprise',
      price: '$499',
      period: 'per month',
      description: 'For large portfolios and institutional investors',
      features: [
        'Everything in Pro, plus:',
        'Advanced Portfolio Analytics',
        'Real-time Market Intelligence',
        'Custom API Integration',
        'Dedicated Account Manager',
        'Quarterly Strategy Sessions',
        'White-label Reports',
        'M&A Advisory Services',
        'Custom Data Exports',
        'Multi-user Access'
      ],
      buttonText: 'Contact Sales',
      buttonVariant: 'primary' as const,
      highlight: true
    }
  ];

  return (
    <div className="container mx-auto px-4 py-16">
      <div className="text-center mb-12">
        <h1 className="text-4xl font-bold mb-4">Choose Your Plan</h1>
        <p className="text-xl text-gray-600 max-w-2xl mx-auto">
          Get access to industry-leading analytics and insights to make better decisions
          for your gas station portfolio
        </p>
      </div>

      <div className="grid md:grid-cols-2 gap-8 max-w-5xl mx-auto">
        {plans.map((plan) => (
          <Card key={plan.name} className={cn(
            "p-8",
            plan.highlight && "border-2 border-blue-600"
          )}>
            <div className="flex flex-col h-full">
              {plan.highlight && (
                <div className="bg-blue-600 text-white text-sm font-semibold px-3 py-1 rounded-full self-start mb-4">
                  Most Popular
                </div>
              )}
              <h2 className="text-2xl font-bold mb-2">{plan.name}</h2>
              <div className="mb-4">
                <span className="text-4xl font-bold">{plan.price}</span>
                <span className="text-gray-600 ml-2">{plan.period}</span>
              </div>
              <p className="text-gray-600 mb-6">{plan.description}</p>
              
              <div className="flex-grow">
                <h3 className="font-semibold mb-4">Features include:</h3>
                <ul className="space-y-3 mb-8">
                  {plan.features.map((feature) => (
                    <li key={feature} className="flex items-start">
                      <Check className="w-5 h-5 text-green-500 mr-2 flex-shrink-0 mt-0.5" />
                      <span>{feature}</span>
                    </li>
                  ))}
                </ul>
              </div>

              <Button
                variant={plan.buttonVariant}
                onClick={() => {
                  if (plan.name === 'Enterprise') {
                    // Navigate to enterprise contact form or open modal
                    navigate('/contact-sales');
                  } else {
                    // Navigate to subscription checkout
                    navigate('/checkout?plan=pro');
                  }
                }}
                className="w-full justify-center"
              >
                {plan.buttonText}
                <ChevronRight className="w-4 h-4 ml-2" />
              </Button>
            </div>
          </Card>
        ))}
      </div>

      <div className="mt-16 text-center">
        <h3 className="text-2xl font-bold mb-4">Need help choosing?</h3>
        <p className="text-gray-600 mb-6">
          Our team is ready to help you find the perfect plan for your business.
        </p>
        <Button
          variant="outline"
          onClick={() => navigate('/contact')}>
          Talk to Sales
        </Button>
      </div>
    </div>
  );
}
