import { useState, useEffect } from 'react';
import { ValuationData, ValidationErrors, SavedValuation } from '../types/valuation';

const STORAGE_KEY = 'gasMan2000_valuations';

export function useValuationForm(initialData: ValuationData) {
  const [data, setData] = useState<ValuationData>(initialData);
  const [errors, setErrors] = useState<ValidationErrors>({});
  const [savedValuations, setSavedValuations] = useState<SavedValuation[]>([]);

  // Load saved valuations from localStorage on mount
  useEffect(() => {
    const saved = localStorage.getItem(STORAGE_KEY);
    if (saved) {
      setSavedValuations(JSON.parse(saved));
    }
  }, []);

  // Validate a single field
  const validateField = (field: keyof ValuationData, value: any): string => {
    // All fields are optional, so no validation errors
    return '';
  };

  const validateFinancialMetrics = (data: ValuationData) => {
    const errors: ValidationErrors = {};

    // Only validate if values are provided and are negative
    if (data.annualGallons && data.annualGallons < 0) {
      errors.annualGallons = 'Annual gallons cannot be negative';
    }

    if (data.fuelMargin && data.fuelMargin < 0) {
      errors.fuelMargin = 'Fuel margin cannot be negative';
    }

    if (data.storeRevenue && data.storeRevenue < 0) {
      errors.storeRevenue = 'Store revenue cannot be negative';
    }

    if (data.operatingExpenses && data.operatingExpenses < 0) {
      errors.operatingExpenses = 'Operating expenses cannot be negative';
    }

    if (data.salaries && data.salaries < 0) {
      errors.salaries = 'Salaries cannot be negative';
    }

    if (data.utilities && data.utilities < 0) {
      errors.utilities = 'Utilities cannot be negative';
    }

    if (data.propertyTax && data.propertyTax < 0) {
      errors.propertyTax = 'Property tax cannot be negative';
    }

    return errors;
  };

  // Validate all fields
  const validateForm = async (): Promise<boolean> => {
    const newErrors: ValidationErrors = {};
    let isValid = true;

    Object.keys(data).forEach((key) => {
      const error = validateField(key as keyof ValuationData, data[key as keyof ValuationData]);
      if (error) {
        newErrors[key] = error;
        isValid = false;
      }
    });

    const financialErrors = validateFinancialMetrics(data);
    Object.assign(newErrors, financialErrors);
    if (Object.keys(financialErrors).length > 0) {
      isValid = false;
    }

    setErrors(newErrors);

    // Log errors to console for debugging
    if (!isValid) {
      console.error('Validation errors:', newErrors);
    }

    return isValid;
  };

  // Update a field and validate it
  const updateField = (updates: Partial<ValuationData>) => {
    const newData = { ...data, ...updates };
    setData(newData);

    // Validate updated fields
    const newErrors = { ...errors };
    Object.keys(updates).forEach((key) => {
      const error = validateField(key as keyof ValuationData, updates[key as keyof ValuationData]);
      if (error) {
        newErrors[key] = error;
      } else {
        delete newErrors[key];
      }
    });
    setErrors(newErrors);

    // Auto-save to localStorage
    saveProgress(newData);
  };

  // Save current progress to localStorage
  const saveProgress = (formData: ValuationData = data) => {
    const currentValuations = [...savedValuations];
    const timestamp = new Date().toISOString();
    
    // Update or create autosave entry
    const autosaveIndex = currentValuations.findIndex(v => v.id === 'autosave');
    const autosave: SavedValuation = {
      id: 'autosave',
      name: 'Autosave',
      data: formData,
      lastModified: timestamp
    };

    if (autosaveIndex >= 0) {
      currentValuations[autosaveIndex] = autosave;
    } else {
      currentValuations.push(autosave);
    }

    setSavedValuations(currentValuations);
    localStorage.setItem(STORAGE_KEY, JSON.stringify(currentValuations));
  };

  // Save current valuation with a name
  const saveValuation = (name: string) => {
    const currentValuations = [...savedValuations];
    const timestamp = new Date().toISOString();
    
    const newValuation: SavedValuation = {
      id: crypto.randomUUID(),
      name,
      data,
      lastModified: timestamp
    };

    currentValuations.push(newValuation);
    setSavedValuations(currentValuations);
    localStorage.setItem(STORAGE_KEY, JSON.stringify(currentValuations));
  };

  // Load a saved valuation
  const loadValuation = (id: string) => {
    const valuation = savedValuations.find(v => v.id === id);
    if (valuation) {
      setData(valuation.data);
      setErrors({});
    }
  };

  return {
    data,
    errors,
    savedValuations,
    updateField,
    validateForm,
    validateField,
    saveValuation,
    loadValuation,
    saveProgress
  };
}
