import React, { useState } from 'react';
import { Building2, Filter, MapPin } from 'lucide-react';
import { PropertyType } from '../types/property';
import PropertyFilters, { PropertyFiltersState } from '../components/PropertyFilters';
import PropertyList from '../components/PropertyList';
import PropertyMap from '../components/maps/PropertyMap';
import { Modal } from '../components/ui/Modal';

export default function Marketplace() {
  const [viewMode] = useState('map');
  const [selectedProperty, setSelectedProperty] = useState<PropertyType | null>(null);
  const [showPreview, setShowPreview] = useState(false);

  // Google Maps API Key
  const GOOGLE_MAPS_API_KEY = import.meta.env.VITE_GOOGLE_MAPS_API_KEY;

  const [filters, setFilters] = useState<PropertyFiltersState>({
    priceRange: [500000, 5000000],
    location: '',
    propertyType: [],
    monthlyGallons: [0, 500000],
    hasCarWash: false,
    hasConvenienceStore: false,
    yearBuilt: [1970, 2024],
    listingType: 'all',
    saleType: []
  });

  const [properties] = useState<PropertyType[]>([
    {
      id: '1',
      title: 'Modern Gas Station with Car Wash',
      price: 2500000,
      location: 'Los Angeles, CA',
      coordinates: { lat: 34.0522, lng: -118.2437 },
      monthlyGallons: 150000,
      yearBuilt: 2015,
      features: ['Car Wash', 'Convenience Store', '8 Pumps'],
      images: ['https://tminurcnztragojlogjv.supabase.co/storage/v1/object/sign/Stock%20Photos/stock%20gas%201.jfif?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJTdG9jayBQaG90b3Mvc3RvY2sgZ2FzIDEuamZpZiIsImlhdCI6MTczMTYzNTk0OCwiZXhwIjoxNzYzMTcxOTQ4fQ.WHhWOPZ_HsDmjKFWWlHGyzEaDxFI-FZjRGJ7x5zBm9A&t=2024-11-15T01%3A59%3A08.201Z'],
      description: 'Prime location with high traffic. Features modern equipment and recently renovated facilities.',
      listingType: 'sale',
      saleType: 'exclusive',
      propertySize: 25000,
      lotSize: 45000,
      zoning: 'Commercial',
      trafficCount: 45000,
      financials: {
        grossRevenue: 3600000,
        netIncome: 480000,
        fuelMargin: 0.25,
        storeRevenue: 960000
      }
    },
    {
      id: '2',
      title: 'Highway Gas Station Complex',
      price: 3500000,
      location: 'Sacramento, CA',
      coordinates: { lat: 38.5816, lng: -121.4944 },
      monthlyGallons: 200000,
      yearBuilt: 2018,
      features: ['Convenience Store', '12 Pumps', 'Restaurant'],
      images: ['https://tminurcnztragojlogjv.supabase.co/storage/v1/object/sign/Stock%20Photos/stock%20gas%202.jfif?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJTdG9jayBQaG90b3Mvc3RvY2sgZ2FzIDIuamZpZiIsImlhdCI6MTczMTYzNzQ3NywiZXhwIjoxNzYzMTczNDc3fQ.yUmMUNVM1zDvf5Wn_kEmhZ_xQH5vcwi1gdBP3ZTaEjk&t=2024-11-15T02%3A24%3A37.452Z'],
      description: 'Strategic highway location with multiple revenue streams. High-volume station with growth potential.',
      listingType: 'sale',
      saleType: 'public',
      propertySize: 35000,
      lotSize: 65000,
      zoning: 'Commercial',
      trafficCount: 65000,
      financials: {
        grossRevenue: 4800000,
        netIncome: 720000,
        fuelMargin: 0.28,
        storeRevenue: 1200000
      }
    },
    {
      id: '3',
      title: 'Urban Gas Station & Service Center',
      price: 4200000,
      location: 'San Francisco, CA',
      coordinates: { lat: 37.7749, lng: -122.4194 },
      monthlyGallons: 180000,
      yearBuilt: 2016,
      features: ['Service Center', '10 Pumps', 'EV Charging'],
      images: ['https://tminurcnztragojlogjv.supabase.co/storage/v1/object/sign/Stock%20Photos/stock%20gas%203.jfif?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJTdG9jayBQaG90b3Mvc3RvY2sgZ2FzIDMuamZpZiIsImlhdCI6MTczMTYzNzU1MiwiZXhwIjoxNzYzMTczNTUyfQ.2T77Tpbl1Mdsqw3dPrkclLJWjwR2REHT-cwxxZJERtI&t=2024-11-15T02%3A25%3A52.504Z'],
      description: 'Modern facility in prime urban location with EV charging and service center.',
      listingType: 'sale',
      saleType: 'exclusive',
      propertySize: 30000,
      lotSize: 50000,
      zoning: 'Commercial',
      trafficCount: 55000,
      financials: {
        grossRevenue: 4200000,
        netIncome: 650000,
        fuelMargin: 0.30,
        storeRevenue: 1100000
      }
    },
    {
      id: '4',
      title: 'Suburban Gas Station & Car Wash Complex',
      price: 3800000,
      location: 'San Diego, CA',
      coordinates: { lat: 32.7157, lng: -117.1611 },
      monthlyGallons: 165000,
      yearBuilt: 2019,
      features: ['Premium Car Wash', 'Convenience Store', '6 Pumps', 'Self-Service Vacuum'],
      images: ['https://tminurcnztragojlogjv.supabase.co/storage/v1/object/sign/Stock%20Photos/stock%20gas%204.jfif?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJTdG9jayBQaG90b3Mvc3RvY2sgZ2FzIDQuamZpZiIsImlhdCI6MTczMTYzNzcwMCwiZXhwIjoxNzYzMTczNzAwfQ.MXodGFYnaCrmjRfBWuG38ZBaD47-0_ORWF-dyIVwavA&t=2024-11-15T02%3A28%3A20.158Z'],
      description: 'Recently built facility in growing suburban area with premium car wash services.',
      listingType: 'sale',
      saleType: 'public',
      propertySize: 28000,
      lotSize: 55000,
      zoning: 'Commercial',
      trafficCount: 40000,
      financials: {
        grossRevenue: 3900000,
        netIncome: 580000,
        fuelMargin: 0.27,
        storeRevenue: 980000
      }
    },
    {
      id: '5',
      title: 'Truck Stop & Travel Center',
      price: 5500000,
      location: 'Fresno, CA',
      coordinates: { lat: 36.7378, lng: -119.7871 },
      monthlyGallons: 300000,
      yearBuilt: 2017,
      features: ['Truck Parking', 'Restaurant', '16 Pumps', 'Diesel Service', 'Shower Facilities'],
      images: ['https://tminurcnztragojlogjv.supabase.co/storage/v1/object/sign/Stock%20Photos/stock%20gas%205.jfif?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJTdG9jayBQaG90b3Mvc3RvY2sgZ2FzIDUuamZpZiIsImlhdCI6MTczMTYzNzg5NSwiZXhwIjoxNzYzMTczODk1fQ.110-mbIFX9cQD929LP4uvcoXRI3cjAt8vkqKEe6-Pus&t=2024-11-15T02%3A31%3A35.539Z'],
      description: 'High-volume truck stop on major interstate with comprehensive travel center amenities.',
      listingType: 'sale',
      saleType: 'exclusive',
      propertySize: 45000,
      lotSize: 120000,
      zoning: 'Commercial',
      trafficCount: 85000,
      financials: {
        grossRevenue: 7200000,
        netIncome: 960000,
        fuelMargin: 0.32,
        storeRevenue: 1800000
      }
    },
    {
      id: '6',
      title: 'Downtown Express Station',
      price: 2800000,
      location: 'San Jose, CA',
      coordinates: { lat: 37.3382, lng: -121.8863 },
      monthlyGallons: 140000,
      yearBuilt: 2020,
      features: ['Express Store', '4 Pumps', 'EV Charging', 'Coffee Bar'],
      images: ['https://images.unsplash.com/photo-1512909481869-0eaa1e9817ba?auto=format&fit=crop&q=80'],
      description: 'Modern compact station in prime downtown location with focus on quick service and EV charging.',
      listingType: 'sale',
      saleType: 'public',
      propertySize: 15000,
      lotSize: 25000,
      zoning: 'Commercial',
      trafficCount: 50000,
      financials: {
        grossRevenue: 3200000,
        netIncome: 420000,
        fuelMargin: 0.26,
        storeRevenue: 850000
      }
    }
  ]);

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex items-center gap-3 mb-8">
        <Building2 className="w-8 h-8 text-blue-600" />
        <div>
          <h1 className="text-3xl font-bold mb-2">Gas Station Marketplace</h1>
          <p className="text-gray-600">Browse available properties in your target market</p>
        </div>
      </div>

      <div className="flex flex-col lg:flex-row gap-8">
        <aside className="lg:w-80">
          <div className="bg-white rounded-xl shadow-lg p-6 sticky top-8">
            <div className="flex items-center gap-2 mb-6">
              <Filter className="w-5 h-5 text-blue-600" />
              <h2 className="text-xl font-semibold">Filters</h2>
            </div>
            <PropertyFilters filters={filters} onChange={setFilters} />
          </div>
        </aside>

        <main className="flex-1">
          {/* Map View */}
          <div className="bg-white rounded-xl shadow-lg p-6 mb-8">
            <div className="aspect-[16/9] bg-gray-100 rounded-lg overflow-hidden relative">
              <PropertyMap
                properties={properties}
                selectedProperty={selectedProperty}
                onPropertySelect={(property) => {
                  setSelectedProperty(property);
                  setShowPreview(true);
                }}
                apiKey={GOOGLE_MAPS_API_KEY}
              />
            </div>
          </div>

          {/* Property List */}
          <PropertyList 
            properties={properties} 
            onSelect={(property) => {
              setSelectedProperty(property);
              setShowPreview(true);
            }} 
          />
        </main>
      </div>

      {/* Property Preview Modal */}
      {selectedProperty && showPreview && (
        <Modal
          isOpen={showPreview}
          onClose={() => setShowPreview(false)}
          title={selectedProperty.title}
        >
          <div className="space-y-4">
            <img
              src={selectedProperty.images[0]}
              alt={selectedProperty.title}
              className="w-full h-64 object-cover rounded-lg"
            />
            
            <div className="grid grid-cols-2 gap-4">
              <div>
                <p className="text-gray-600">Location</p>
                <p className="font-semibold">{selectedProperty.location}</p>
              </div>
              <div>
                <p className="text-gray-600">Price</p>
                <p className="font-semibold text-blue-600">
                  ${(selectedProperty.price / 1000000).toFixed(1)}M
                </p>
              </div>
              <div>
                <p className="text-gray-600">Monthly Volume</p>
                <p className="font-semibold">
                  {(selectedProperty.monthlyGallons / 1000).toFixed(0)}K gal
                </p>
              </div>
              <div>
                <p className="text-gray-600">Property Size</p>
                <p className="font-semibold">
                  {selectedProperty.propertySize.toLocaleString()} sq ft
                </p>
              </div>
            </div>

            <div>
              <p className="text-gray-600 mb-2">Features</p>
              <div className="flex flex-wrap gap-2">
                {selectedProperty.features.map((feature) => (
                  <span
                    key={feature}
                    className="px-3 py-1 bg-blue-50 text-blue-600 rounded-full text-sm"
                  >
                    {feature}
                  </span>
                ))}
              </div>
            </div>

            <p className="text-gray-600">{selectedProperty.description}</p>

            <div className="flex justify-end gap-4">
              <button className="px-4 py-2 text-blue-600 hover:bg-blue-50 rounded-lg">
                Save Property
              </button>
              <button className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700">
                Contact Seller
              </button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}