import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronLeft, ChevronRight, User, Lock } from 'lucide-react';
import BasicInfo from './steps/BasicInfo';
import PropertyDetails from './steps/PropertyDetails';
import OperationalMetrics from './steps/OperationalMetrics';
import ProfitCenters from './steps/ProfitCenters';
import FinancialMetrics from './steps/FinancialMetrics';
import ValuationResult from '../ValuationResult';
import { useValuationForm } from '../../hooks/useValuationForm';
import { useSubscription } from '../../contexts/SubscriptionContext';
import { useAuthStore } from '../../store/useAuthStore';
import { useNavigate } from 'react-router-dom';
import { LoginModal } from './LoginModal';
import axios from 'axios';
import { supabase } from '../../lib/supabase';
import { ValuationData, ValidationErrors } from '../../types/valuation';

// Error Boundary Component
class ErrorBoundary extends React.Component<{ children: React.ReactNode }, { hasError: boolean }> {
  constructor(props: { children: React.ReactNode }) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.error('Error caught in ErrorBoundary:', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <h2>Oops, something went wrong. Please try again later.</h2>;
    }

    return this.props.children; 
  }
}

export default function ValuationWizard() {
  console.log('ValuationWizard component mounted');

  const [currentStep, setCurrentStep] = useState(0);
  const [showResults, setShowResults] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [predictedValue, setPredictedValue] = useState<number | null>(null);
  const { isProOrHigher } = useSubscription();
  const { isAuthenticated } = useAuthStore();
  const navigate = useNavigate();
  const {
    data,
    errors,
    savedValuations,
    updateField,
    validateForm,
    saveValuation,
    loadValuation,
    saveProgress
  } = useValuationForm({
    // Basic Info
    propertyName: '',
    location: '',
    propertyType: '',
    ownership: 'owned',
    leaseTermYears: 0,
    brandAffiliation: 'unbranded',
    brandName: '',
    fuelSupplyYears: 0,

    // Operational Metrics
    tankCapacity: 0,
    operatingHours: '24/7',
    specificHours: '',
    numberOfPumps: 0,
    fuelTypes: [],
    annualGallons: 0,
    fuelMargin: 0,

    // Property Details
    propertySize: 0,
    lotSize: 0,
    yearBuilt: new Date().getFullYear(),
    lastRenovated: undefined,
    zoningType: '',
    trafficCount: 0,

    // Profit Centers
    hasCarWash: false,
    carWashType: undefined,
    carWashRevenue: 0,
    hasFoodService: false,
    foodServiceType: [],
    foodServiceRevenue: 0,
    foodServiceOwnership: 'owned',
    foodServiceLeaseYears: 0,
    foodServiceRent: 0,
    additionalServices: [],

    // Financial Metrics
    storeRevenue: 0,
    operatingExpenses: 0,
    salaries: 0,
    utilities: 0,
    propertyTax: 0,
    isAnnual: false
  });

  console.log('Initial data:', data);
  console.log('Initial currentStep:', currentStep);
  console.log('Initial savedValuations:', savedValuations);

  useEffect(() => {
    console.log('Current step changed:', currentStep);
  }, [currentStep]);

  useEffect(() => {
    console.log('Saved valuations updated:', savedValuations);
  }, [savedValuations]);

  const steps = [
    {
      title: 'Basic Information',
      component: <BasicInfo data={data} onUpdate={updateField} errors={errors} />
    },
    {
      title: 'Property Details',
      component: <PropertyDetails data={data} onUpdate={updateField} errors={errors} />
    },
    {
      title: 'Operational Metrics',
      component: <OperationalMetrics data={data} onUpdate={updateField} errors={errors} />
    },
    {
      title: 'Profit Centers',
      component: <ProfitCenters data={data} onUpdate={updateField} errors={errors} />
    },
    {
      title: 'Financial Metrics',
      component: <FinancialMetrics data={data} onUpdate={updateField} errors={errors} />
    }
  ];

  // Handle keyboard navigation for Pro users
  useEffect(() => {
    if (!isProOrHigher()) return;

    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'ArrowRight' && currentStep < steps.length - 1) {
        goToNextStep();
      } else if (e.key === 'ArrowLeft' && currentStep > 0) {
        goToPreviousStep();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [currentStep, isProOrHigher]);

  const goToNextStep = () => {
    setCurrentStep((prev) => Math.min(prev + 1, steps.length - 1));
    saveProgress();
  };

  const goToPreviousStep = () => {
    setCurrentStep((prev) => Math.max(prev - 1, 0));
  };

  const makePredictionRequest = async () => {
    try {
      const storeToken = useAuthStore.getState().token;
      const { data: { session } } = await supabase.auth.getSession();
      const token = storeToken || session?.access_token;
      
      if (!token) {
        throw new Error('No authentication token found');
      }

      const response = await axios.post(
        import.meta.env.VITE_PREDICTION_URL + '/predict',
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      let predictedValue;
      if (typeof response.data.predicted_value === 'string') {
        predictedValue = parseFloat(response.data.predicted_value);
      } else {
        predictedValue = response.data.predicted_value;
      }

      if (typeof predictedValue !== 'number' || isNaN(predictedValue)) {
        throw new Error('Invalid predicted value received');
      }

      setPredictedValue(predictedValue);
      setShowResults(true);
    } catch (error) {
      console.error('Error during prediction request:', error);
      // Handle error appropriately
    }
  };

  const handleFinish = async () => {
    saveProgress();
    if (!isAuthenticated) {
      setShowLoginModal(true);
    } else {
      await makePredictionRequest();
    }
  };

  const handleLoginSuccess = async () => {
    setShowLoginModal(false);
    await makePredictionRequest();
  };

  if (showResults) {
    console.log('Passing predictedValue to ValuationResult:', predictedValue);
    return <ValuationResult 
      data={data} 
      predictedValue={predictedValue} 
      onBack={() => setCurrentStep(steps.length - 1)} 
    />;
  }

  return (
    <div className="max-w-4xl mx-auto p-6">
      {/* Progress Bar */}
      <div className="mb-8">
        <div className="flex justify-between mb-2">
          {steps.map((step, index) => (
            <div
              key={index}
              className={`text-sm font-medium ${
                index <= currentStep ? 'text-blue-600' : 'text-gray-400'
              }`}
            >
              {step.title}
            </div>
          ))}
        </div>
        <div className="h-2 bg-gray-200 rounded-full">
          <div
            className="h-full bg-blue-600 rounded-full transition-all duration-300"
            style={{ width: `${(currentStep / (steps.length - 1)) * 100}%` }}
          />
        </div>
      </div>

      {/* Step Content */}
      <AnimatePresence mode="wait">
        <motion.div
          key={currentStep}
          initial={{ opacity: 0, x: 20 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: -20 }}
          transition={{ duration: 0.2 }}
        >
          {steps[currentStep].component}
        </motion.div>
      </AnimatePresence>

      {/* Navigation */}
      <div className="mt-8 flex justify-between">
        <button
          onClick={goToPreviousStep}
          disabled={currentStep === 0}
          className={`flex items-center px-4 py-2 rounded-lg ${
            currentStep === 0
              ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
              : 'bg-blue-100 text-blue-600 hover:bg-blue-200'
          }`}
        >
          <ChevronLeft className="w-5 h-5 mr-2" />
          Previous
        </button>

        {currentStep < steps.length - 1 ? (
          <button
            onClick={goToNextStep}
            className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
          >
            Next
            <ChevronRight className="w-5 h-5 ml-2" />
          </button>
        ) : (
          <button
            onClick={handleFinish}
            className="flex items-center px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700"
          >
            View Results
          </button>
        )}
      </div>

      <LoginModal
        isOpen={showLoginModal}
        onClose={() => setShowLoginModal(false)}
        onSuccess={handleLoginSuccess}
      />
    </div>
  );
}