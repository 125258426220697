import React from 'react';

export default function Checkout() {
  return (
    <div className="container mx-auto px-4 py-16">
      <h1 className="text-4xl font-bold mb-4">Checkout</h1>
      <p className="text-xl text-gray-600">Proceed with your subscription plan.</p>
      {/* Add checkout form or integration here */}
    </div>
  );
}
