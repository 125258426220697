import React from 'react';
import { useToastStore } from '../store/useToastStore';
import { X } from 'lucide-react';

export default function Toast() {
  const { message, type, isVisible, hideToast } = useToastStore();

  if (!isVisible) return null;

  const bgColor = type === 'error' ? 'bg-red-500' : 
                 type === 'success' ? 'bg-green-500' : 
                 'bg-blue-500';

  return (
    <div className={`fixed bottom-4 right-4 ${bgColor} text-white px-6 py-3 rounded-lg shadow-lg flex items-center gap-3`}>
      <span>{message}</span>
      <button
        onClick={hideToast}
        className="hover:bg-white/20 rounded-full p-1 transition-colors"
      >
        <X className="w-4 h-4" />
      </button>
    </div>
  );
}
