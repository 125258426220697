        import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Layout from './components/Layout';
import AuthGuard from './components/AuthGuard';
import ErrorBoundary from './components/ErrorBoundary';
import Toast from './components/Toast';

// Pages
import Dashboard from './pages/Dashboard';
import Analytics from './pages/Analytics';
import Valuation from './pages/Valuation';
import PropertySearch from './pages/PropertySearch';
import LocationAnalysis from './pages/LocationAnalysis';
import Research from './pages/Research';
import VendorList from './pages/VendorList';
import Home from './pages/Home';
import Login from './pages/Login';
import Pricing from './pages/Pricing';
import Checkout from './pages/Checkout';
import ContactSales from './pages/ContactSales';
import Signup from './pages/Signup';
import SubscriptionTier from './pages/SubscriptionTier'; // Assuming SubscriptionTier component is in './pages/SubscriptionTier'

export default function App() {
  return (
    <ErrorBoundary>
      <Routes>
        {/* Auth Routes */}
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/subscription-tier" element={<SubscriptionTier />} />

        {/* Public Routes */}
        <Route element={<AuthGuard requireAuth={false}><Layout /></AuthGuard>}>
          <Route path="/" element={<Home />} />
          <Route path="/valuation" element={<Valuation />} />
          <Route path="/properties" element={<PropertySearch />} />
          <Route path="/location-analysis" element={<LocationAnalysis />} />
          <Route path="/research" element={<Research />} />
          <Route path="/vendors" element={<VendorList />} />
          <Route path="/analytics" element={<Analytics />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/contact-sales" element={<ContactSales />} />
        </Route>

        {/* Protected Routes */}
        <Route element={<AuthGuard requireAuth={true}><Layout /></AuthGuard>}>
          <Route path="/dashboard" element={<Dashboard />} />
        </Route>
      </Routes>
      <Toast />
    </ErrorBoundary>
  );
}