import React from 'react';
import { Bell, TrendingUp, AlertTriangle } from 'lucide-react';

export default function MarketAlerts() {
  const alerts = [
    {
      id: '1',
      type: 'price',
      message: 'Fuel prices trending up in Los Angeles area',
      date: '2024-03-15',
      icon: <TrendingUp className="w-4 h-4" />
    },
    {
      id: '2',
      type: 'regulation',
      message: 'New environmental regulations effective April 1st',
      date: '2024-03-14',
      icon: <AlertTriangle className="w-4 h-4" />
    }
  ];

  return (
    <div className="bg-white rounded-xl shadow-lg p-6">
      <div className="flex items-center gap-2 mb-6">
        <Bell className="w-6 h-6 text-blue-600" />
        <h2 className="text-xl font-semibold">Market Alerts</h2>
      </div>

      <div className="space-y-4">
        {alerts.map((alert) => (
          <div
            key={alert.id}
            className="p-4 bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors"
          >
            <div className="flex items-start gap-3">
              <div className="text-blue-600 mt-1">
                {alert.icon}
              </div>
              <div>
                <p className="font-medium mb-1">{alert.message}</p>
                <p className="text-sm text-gray-600">
                  {new Date(alert.date).toLocaleDateString()}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>

      <button className="w-full mt-4 text-blue-600 hover:text-blue-700 text-sm font-semibold">
        View All Alerts
      </button>
    </div>
  );
}