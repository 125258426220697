import React from 'react';
import { Slider } from './ui/Slider';
import { Checkbox } from './ui/Checkbox';

export interface PropertyFiltersState {
  priceRange: [number, number];
  location: string;
  propertyType: string[];
  monthlyGallons: [number, number];
  hasCarWash: boolean;
  hasConvenienceStore: boolean;
  yearBuilt: [number, number];
  listingType: string;
  saleType: string[];
}

interface PropertyFiltersProps {
  filters: PropertyFiltersState;
  onChange: (filters: PropertyFiltersState) => void;
}

export default function PropertyFilters({ filters, onChange }: PropertyFiltersProps) {
  const locations = ['Los Angeles, CA', 'San Francisco, CA', 'Sacramento, CA', 'San Diego, CA'];
  const propertyTypes = ['Highway', 'Urban', 'Suburban', 'Rural'];

  const handleChange = (key: keyof PropertyFiltersState, value: any) => {
    onChange({ ...filters, [key]: value });
  };

  const formatPrice = (value: number) => 
    `$${(value / 1000000).toFixed(1)}M`;

  const formatGallons = (value: number) =>
    `${(value / 1000).toFixed(0)}K`;

  return (
    <div className="space-y-6">
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Price Range
        </label>
        <Slider
          min={500000}
          max={5000000}
          step={100000}
          value={filters.priceRange}
          onChange={(value) => handleChange('priceRange', value as [number, number])}
          formatLabel={formatPrice}
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Location
        </label>
        <select
          value={filters.location}
          onChange={(e) => handleChange('location', e.target.value)}
          className="w-full p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
        >
          <option value="">All Locations</option>
          {locations.map((location) => (
            <option key={location} value={location}>
              {location}
            </option>
          ))}
        </select>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Property Type
        </label>
        <div className="space-y-2">
          {propertyTypes.map((type) => (
            <Checkbox
              key={type}
              label={type}
              checked={filters.propertyType.includes(type)}
              onChange={(checked) => {
                const newTypes = checked
                  ? [...filters.propertyType, type]
                  : filters.propertyType.filter((t) => t !== type);
                handleChange('propertyType', newTypes);
              }}
            />
          ))}
        </div>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Monthly Gallons
        </label>
        <Slider
          min={0}
          max={500000}
          step={10000}
          value={filters.monthlyGallons}
          onChange={(value) => handleChange('monthlyGallons', value as [number, number])}
          formatLabel={formatGallons}
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Year Built
        </label>
        <Slider
          min={1970}
          max={2024}
          step={1}
          value={filters.yearBuilt}
          onChange={(value) => handleChange('yearBuilt', value as [number, number])}
        />
      </div>

      <div className="space-y-2">
        <Checkbox
          label="Has Car Wash"
          checked={filters.hasCarWash}
          onChange={(checked) => handleChange('hasCarWash', checked)}
        />
        <Checkbox
          label="Has Convenience Store"
          checked={filters.hasConvenienceStore}
          onChange={(checked) => handleChange('hasConvenienceStore', checked)}
        />
      </div>
    </div>
  );
}