import React, { useState, useEffect } from 'react';
import { BarChart2, Calendar, Download, Share2, Filter, ChevronLeft, ChevronRight } from 'lucide-react';
import { Button } from '../components/ui/Button';
import { Select } from '../components/ui/Select';
import { Card } from '../components/ui/Card';
import { DataTable } from '../components/ui/DataTable';
import { supabase } from '../lib/supabase';
import { Input } from '../components/ui/Input';

// Analytics Components
import MetricsOverview from '../components/analytics/MetricsOverview';
import MarketTrends from '../components/analytics/MarketTrends';
import CompetitorMap from '../components/analytics/CompetitorMap';
import RevenueBreakdown from '../components/analytics/RevenueBreakdown';

interface Property {
  id: number;
  address: string;
  city: string;
  state: string;
  price: string;
  brand: string;
  size: number;
  fuelVolume: number;
  cstoreSales: number;
  customerCount: number;
  profits: number;
}

interface FilterState {
  brand: string;
  city: string;
  minSize: number;
  maxSize: number;
  minFuelVolume: number;
  maxFuelVolume: number;
  minCstoreSales: number;
  maxCstoreSales: number;
  similarityRange: number;
}

interface ComparisonMetrics {
  sizeDiff: number;
  fuelVolumeDiff: number;
  cstoreSalesDiff: number;
  overallSimilarity: number;
}

export default function Analytics() {
  const [properties, setProperties] = useState<Property[]>([]);
  const [referenceProperty, setReferenceProperty] = useState<Property | null>(null);
  const [filters, setFilters] = useState<FilterState>({
    brand: '',
    city: '',
    minSize: 0,
    maxSize: 0,
    minFuelVolume: 0,
    maxFuelVolume: 0,
    minCstoreSales: 0,
    maxCstoreSales: 0,
    similarityRange: 0.2,
  });

  useEffect(() => {
    fetchProperties();
  }, []);

  async function fetchProperties() {
    try {
      const { data, error } = await supabase
        .from('AnalyticsTable')
        .select(`
          ID,
          PropertyAddress,
          PropertyCity,
          PropertyState,
          SalePrice,
          Size,
          PropertyName,
          "2023FuelVolume",
          "2023Cstoresales",
          "2023grossprofit"
        `);
      
      if (error) throw error;
      
      // Transform the data to match Property type
      const transformedData = (data || []).map(analytics => ({
        id: analytics.ID,
        address: analytics.PropertyAddress || '',
        city: analytics.PropertyCity || '',
        state: analytics.PropertyState || '',
        price: analytics.SalePrice || '',
        brand: analytics.PropertyName || '',
        size: parseFloat((analytics.Size || '').replace(/,/g, '')) || 0,
        fuelVolume: parseFloat((analytics["2023FuelVolume"] || '').replace(/,/g, '')) || 0,
        cstoreSales: analytics["2023Cstoresales"] || 0,
        customerCount: 0, // Not available in current data
        profits: parseFloat((analytics["2023grossprofit"] || '').replace(/,/g, '')) || 0
      }));
      
      setProperties(transformedData);
    } catch (error) {
      console.error('Error fetching properties:', error);
    }
  }

  const calculateSimilarity = (property: Property): ComparisonMetrics => {
    if (!referenceProperty) return {
      sizeDiff: 0,
      fuelVolumeDiff: 0,
      cstoreSalesDiff: 0,
      overallSimilarity: 0
    };

    const sizeDiff = Math.abs(property.size - referenceProperty.size) / referenceProperty.size;
    const fuelVolumeDiff = Math.abs(property.fuelVolume - referenceProperty.fuelVolume) / referenceProperty.fuelVolume;
    const cstoreSalesDiff = Math.abs(property.cstoreSales - referenceProperty.cstoreSales) / referenceProperty.cstoreSales;
    
    const overallSimilarity = 1 - ((sizeDiff + fuelVolumeDiff + cstoreSalesDiff) / 3);

    return {
      sizeDiff,
      fuelVolumeDiff,
      cstoreSalesDiff,
      overallSimilarity
    };
  };

  const filteredProperties = properties.filter(property => {
    if (property.id === referenceProperty?.id) return false;
    
    const similarity = calculateSimilarity(property);
    if (similarity.overallSimilarity < (1 - filters.similarityRange)) return false;
    
    if (filters.brand && property.brand !== filters.brand) return false;
    if (filters.city && property.city !== filters.city) return false;
    if (filters.minSize && property.size < filters.minSize) return false;
    if (filters.maxSize && property.size > filters.maxSize) return false;
    if (filters.minFuelVolume && property.fuelVolume < filters.minFuelVolume) return false;
    if (filters.maxFuelVolume && property.fuelVolume > filters.maxFuelVolume) return false;
    if (filters.minCstoreSales && property.cstoreSales < filters.minCstoreSales) return false;
    if (filters.maxCstoreSales && property.cstoreSales > filters.maxCstoreSales) return false;
    
    return true;
  });

  return (
    <div className="container mx-auto p-6 space-y-6">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Gas Station Analytics</h1>
        <div className="flex gap-4">
          <Button variant="outline">
            <Calendar className="w-4 h-4 mr-2" />
            Last 30 Days
          </Button>
          <Button variant="outline">
            <Download className="w-4 h-4 mr-2" />
            Export
          </Button>
          <Button variant="outline">
            <Share2 className="w-4 h-4 mr-2" />
            Share
          </Button>
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        <Card className="lg:col-span-1">
          <h2 className="text-lg font-semibold mb-4">Reference Property</h2>
          <Select
            label="Select Property"
            value={referenceProperty?.id.toString() || ''}
            onChange={(value) => {
              const selected = properties.find(p => p.id.toString() === value);
              setReferenceProperty(selected || null);
            }}
            options={properties.map(p => ({
              value: p.id.toString(),
              label: `${p.address}, ${p.city}`
            }))}
          />
          {referenceProperty && (
            <div className="mt-4 space-y-2">
              <p><strong>Brand:</strong> {referenceProperty.brand}</p>
              <p><strong>Size:</strong> {referenceProperty.size} sq ft</p>
              <p><strong>Fuel Volume:</strong> {referenceProperty.fuelVolume} gal/month</p>
              <p><strong>C-Store Sales:</strong> ${referenceProperty.cstoreSales.toLocaleString()}</p>
            </div>
          )}
        </Card>

        <Card className="lg:col-span-2">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-lg font-semibold">Filters</h2>
            <Button
              variant="outline"
              onClick={() => setFilters({
                brand: '',
                city: '',
                minSize: 0,
                maxSize: 0,
                minFuelVolume: 0,
                maxFuelVolume: 0,
                minCstoreSales: 0,
                maxCstoreSales: 0,
                similarityRange: 0.2,
              })}
            >
              Reset Filters
            </Button>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <Select
              label="Brand"
              value={filters.brand}
              onChange={(value) => setFilters(prev => ({ ...prev, brand: value }))}
              options={Array.from(new Set(properties.map(p => p.brand))).map(brand => ({
                value: brand,
                label: brand
              }))}
            />
            <Select
              label="City"
              value={filters.city}
              onChange={(value) => setFilters(prev => ({ ...prev, city: value }))}
              options={Array.from(new Set(properties.map(p => p.city))).map(city => ({
                value: city,
                label: city
              }))}
            />
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Size Range (sq ft)</label>
              <div className="flex gap-2">
                <Input
                  type="number"
                  placeholder="Min"
                  value={filters.minSize || ''}
                  onChange={(e) => setFilters(prev => ({ ...prev, minSize: parseInt(e.target.value) || 0 }))}
                />
                <Input
                  type="number"
                  placeholder="Max"
                  value={filters.maxSize || ''}
                  onChange={(e) => setFilters(prev => ({ ...prev, maxSize: parseInt(e.target.value) || 0 }))}
                />
              </div>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Similarity Range</label>
              <Input
                type="range"
                min="0"
                max="1"
                step="0.1"
                value={filters.similarityRange}
                onChange={(e) => setFilters(prev => ({ ...prev, similarityRange: parseFloat(e.target.value) }))}
              />
              <span className="text-sm text-gray-500">{Math.round(filters.similarityRange * 100)}% similar</span>
            </div>
          </div>
        </Card>
      </div>

      <MetricsOverview />

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <MarketTrends />
        <RevenueBreakdown />
      </div>

      <Card>
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-lg font-semibold">Comparable Properties</h2>
          <span className="text-sm text-gray-500">{filteredProperties.length} properties found</span>
        </div>
        <DataTable
          columns={[
            { key: 'address', label: 'Address', sortable: true },
            { key: 'city', label: 'City', sortable: true },
            { key: 'brand', label: 'Brand', sortable: true },
            { key: 'size', label: 'Size (sq ft)', sortable: true },
            { key: 'fuelVolume', label: 'Fuel Volume (gal)', sortable: true },
            { key: 'cstoreSales', label: 'C-Store Sales', sortable: true },
            { key: 'similarity', label: 'Similarity', sortable: true },
          ]}
          data={filteredProperties.map(property => ({
            ...property,
            similarity: `${Math.round(calculateSimilarity(property).overallSimilarity * 100)}%`
          }))}
        />
      </Card>

      <CompetitorMap />
    </div>
  );
}