import React from 'react';
import { cn } from '../../lib/utils';

interface CardProps {
  children: React.ReactNode;
  className?: string;
}

export function Card({ children, className }: CardProps) {
  return (
    <div
      className={cn(
        'bg-white rounded-xl shadow-lg p-6',
        className
      )}
    >
      {children}
    </div>
  );
}

Card.Header = function CardHeader({
  children,
  className
}: CardProps) {
  return (
    <div className={cn('mb-6', className)}>
      {children}
    </div>
  );
};

Card.Title = function CardTitle({
  children,
  className
}: CardProps) {
  return (
    <h3 className={cn('text-xl font-semibold', className)}>
      {children}
    </h3>
  );
};

Card.Description = function CardDescription({
  children,
  className
}: CardProps) {
  return (
    <p className={cn('text-gray-600', className)}>
      {children}
    </p>
  );
};

Card.Content = function CardContent({
  children,
  className
}: CardProps) {
  return (
    <div className={className}>
      {children}
    </div>
  );
};