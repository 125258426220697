import React, { useState } from 'react';
import { Search, Filter, Star, MapPin, Phone, Mail, Shield, ArrowRight } from 'lucide-react';
import VendorFilters from '../components/vendors/VendorFilters';
import VendorGrid from '../components/vendors/VendorGrid';
import VendorCarousel from '../components/vendors/VendorCarousel';
import { Card } from '../components/ui/Card';

export type VendorCategory = 'fuel' | 'equipment' | 'compliance' | 'marketing' | 'construction' | 'financial';

export interface Vendor {
  ID: number;
  VendorName: string;
  VendorType: string;
  VendorSubType: string;
  ContactName: string;
  EmailAddress: string;
  PhoneNumber: string;
  Website: string | null;
  Address: string;
  City: string;
  State: string;
  ZipCode: string | null;
  Notes: string;
  Picture: string;
}

export default function VendorList() {
  const [filters, setFilters] = useState({
    category: '' as VendorCategory | '',
    search: '',
    rating: 0,
    verified: false,
    serviceArea: '',
    services: [] as string[]
  });

  const categories = [
    { id: 'fuel', label: 'Fuel Supply & Delivery', icon: '⛽' },
    { id: 'equipment', label: 'Equipment Maintenance', icon: '🔧' },
    { id: 'compliance', label: 'Compliance Services', icon: '📋' },
    { id: 'marketing', label: 'Marketing & Branding', icon: '📢' },
    { id: 'construction', label: 'Construction', icon: '🏗️' },
    { id: 'financial', label: 'Financial Services', icon: '💰' }
  ];

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="mb-8">
        <h1 className="text-3xl font-bold mb-4">Trusted Vendor Directory</h1>
        <p className="text-gray-600 mb-6">
          Find reliable service providers for your gas station business
        </p>

        {/* Featured Vendors Carousel */}
        <div className="mb-12">
          <h2 className="text-2xl font-semibold mb-4">Featured Vendors</h2>
          <VendorCarousel />
        </div>

        {/* Category Grid */}
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-4 mb-8">
          {categories.map((category) => (
            <button
              key={category.id}
              onClick={() => setFilters({ ...filters, category: category.id as VendorCategory })}
              className={`p-4 rounded-xl text-center transition-colors ${
                filters.category === category.id
                  ? 'bg-blue-600 text-white'
                  : 'bg-white hover:bg-gray-50'
              }`}
            >
              <span className="text-2xl mb-2 block">{category.icon}</span>
              <span className="text-sm font-medium">{category.label}</span>
            </button>
          ))}
        </div>
      </div>

      <div className="flex flex-col lg:flex-row gap-8">
        {/* Filters Sidebar */}
        <aside className="lg:w-80">
          <div className="bg-white rounded-xl shadow-lg p-6 sticky top-8">
            <div className="flex items-center gap-2 mb-6">
              <Filter className="w-5 h-5 text-blue-600" />
              <h2 className="text-xl font-semibold">Filters</h2>
            </div>
            <VendorFilters filters={filters} onChange={setFilters} />
          </div>
        </aside>

        {/* Main Content */}
        <main className="flex-1">
          <div className="mb-6">
            <div className="relative">
              <Search className="absolute left-3 top-3 w-5 h-5 text-gray-400" />
              <input
                type="text"
                placeholder="Search vendors..."
                value={filters.search}
                onChange={(e) => setFilters({ ...filters, search: e.target.value })}
                className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
          </div>
          <VendorGrid filters={filters} />
        </main>
      </div>
    </div>
  );
}