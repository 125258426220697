import React from 'react';
import { Building2, Star, Clock, Bell, Settings } from 'lucide-react';
import SavedProperties from '../components/dashboard/SavedProperties';
import RecentValuations from '../components/dashboard/RecentValuations';
import MarketAlerts from '../components/dashboard/MarketAlerts';
import AccountSettings from '../components/dashboard/AccountSettings';

export default function Dashboard() {
  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex items-center gap-3 mb-8">
        <Building2 className="w-8 h-8 text-blue-600" />
        <h1 className="text-3xl font-bold">My Dashboard</h1>
      </div>

      <div className="grid lg:grid-cols-3 gap-8">
        {/* Main Content */}
        <div className="lg:col-span-2 space-y-8">
          <SavedProperties />
          <RecentValuations />
        </div>

        {/* Sidebar */}
        <div className="space-y-8">
          <MarketAlerts />
          <AccountSettings />
        </div>
      </div>
    </div>
  );
}