import React from 'react';
import { Checkbox } from './ui/Checkbox';

interface ArticleFiltersProps {
  filters: {
    category: string;
    tags: string[];
    searchQuery: string;
  };
  onChange: (filters: any) => void;
}

export default function ArticleFilters({ filters, onChange }: ArticleFiltersProps) {
  const categories = [
    'Market Analysis',
    'Regulations',
    'Technology',
    'Operations',
    'Environmental'
  ];

  const tags = [
    'EV Charging',
    'Fuel Prices',
    'Convenience Store',
    'Car Wash',
    'Compliance',
    'Property Value',
    'Market Trends'
  ];

  return (
    <div className="space-y-6">
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Category
        </label>
        <select
          value={filters.category}
          onChange={(e) => onChange({ ...filters, category: e.target.value })}
          className="w-full p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
        >
          <option value="">All Categories</option>
          {categories.map((category) => (
            <option key={category} value={category}>
              {category}
            </option>
          ))}
        </select>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Topics
        </label>
        <div className="space-y-2">
          {tags.map((tag) => (
            <Checkbox
              key={tag}
              label={tag}
              checked={filters.tags.includes(tag)}
              onChange={(checked) => {
                const newTags = checked
                  ? [...filters.tags, tag]
                  : filters.tags.filter((t) => t !== tag);
                onChange({ ...filters, tags: newTags });
              }}
            />
          ))}
        </div>
      </div>
    </div>
  );
}