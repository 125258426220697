import { PropertyType } from '../types/property';

interface PropertyListProps {
  properties: PropertyType[];
  onSelect: (property: PropertyType) => void;
}

export default function PropertyList({ properties, onSelect }: PropertyListProps) {
  if (properties.length === 0) {
    return (
      <div className="text-center py-12">
        <p className="text-gray-600">No properties found matching your criteria.</p>
      </div>
    );
  }

  return (
    <div className="grid md:grid-cols-2 gap-6">
      {properties.map((property) => (
        <div
          key={property.id}
          className="bg-white rounded-xl shadow-lg overflow-hidden hover:shadow-xl transition-shadow cursor-pointer"
          onClick={() => onSelect(property)}
        >
          <img
            src={property.images[0]}
            alt={property.title}
            className="w-full h-48 object-cover"
          />
          <div className="p-6">
            <div className="flex justify-between items-start mb-4">
              <h3 className="text-xl font-semibold">{property.title}</h3>
              <p className="text-xl font-bold text-blue-600">
                ${(property.price / 1000000).toFixed(1)}M
              </p>
            </div>

            <div className="space-y-2 mb-4">
              <div className="flex items-center gap-2 text-gray-600">
                <span>{property.location}</span>
              </div>
              <div className="flex items-center gap-2 text-gray-600">
                <span>{(property.monthlyGallons / 1000).toFixed(0)}K gal/month</span>
              </div>
              <div className="flex items-center gap-2 text-gray-600">
                <span>Built in {property.yearBuilt}</span>
              </div>
            </div>

            <div className="flex flex-wrap gap-2 mb-4">
              {property.features.map((feature) => (
                <span
                  key={feature}
                  className="px-3 py-1 bg-blue-50 text-blue-600 rounded-full text-sm"
                >
                  {feature}
                </span>
              ))}
            </div>

            <p className="text-gray-600 mb-4 line-clamp-2">
              {property.description}
            </p>

            <button 
              onClick={(e) => {
                e.stopPropagation();
                onSelect(property);
              }}
              className="w-full bg-blue-600 text-white px-4 py-2 rounded-lg font-semibold hover:bg-blue-700 transition-colors flex items-center justify-center gap-2"
            >
              View Details
            </button>
          </div>
        </div>
      ))}
    </div>
  );
}