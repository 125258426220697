import React, { useState, useEffect } from 'react';
import { Mail } from 'lucide-react';
import { Modal } from '../ui/Modal';
import { Button } from '../ui/Button';
import { supabase } from '../../lib/supabase';

interface BrokerConnectionModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export function BrokerConnectionModal({ isOpen, onClose }: BrokerConnectionModalProps) {
  const [brokerData, setBrokerData] = useState<{
    EmailAddress: string;
    VendorName: string;
  } | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [userEmail, setUserEmail] = useState('');
  const [subject, setSubject] = useState('Property Valuation Inquiry');
  const [message, setMessage] = useState('');
  const [sendingEmail, setSendingEmail] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  useEffect(() => {
    async function fetchBrokerData() {
      try {
        const { data, error } = await supabase
          .from('Vendor_List')
          .select('EmailAddress, VendorName')
          .eq('VendorName', 'C-Store Realty')
          .single();

        if (error) throw error;
        setBrokerData(data);
      } catch (err) {
        setError('Failed to load broker information');
        console.error('Error fetching broker data:', err);
      } finally {
        setLoading(false);
      }
    }

    if (isOpen) {
      fetchBrokerData();
    }
  }, [isOpen]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setSendingEmail(true);

    try {
      // In a real implementation, you would send this to your email service
      // For now, we'll just simulate the email sending
      await new Promise(resolve => setTimeout(resolve, 1000));
      setEmailSent(true);
      
      // Reset form after successful send
      setUserEmail('');
      setSubject('Property Valuation Inquiry');
      setMessage('');
    } catch (err) {
      setError('Failed to send email. Please try again.');
    } finally {
      setSendingEmail(false);
    }
  };

  if (loading) {
    return (
      <Modal isOpen={isOpen} onClose={onClose} title="Connect with Broker">
        <div className="flex justify-center items-center h-40">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600" />
        </div>
      </Modal>
    );
  }

  if (error) {
    return (
      <Modal isOpen={isOpen} onClose={onClose} title="Error">
        <div className="text-center py-6">
          <p className="text-red-600">{error}</p>
          <Button onClick={onClose} className="mt-4">Close</Button>
        </div>
      </Modal>
    );
  }

  if (emailSent) {
    return (
      <Modal isOpen={isOpen} onClose={onClose} title="Email Sent">
        <div className="text-center py-6">
          <div className="mb-4 text-green-600">
            <Mail className="w-12 h-12 mx-auto" />
          </div>
          <h3 className="text-xl font-semibold mb-2">Message Sent Successfully!</h3>
          <p className="text-gray-600 mb-4">
            We've forwarded your message to C-Store Realty. They will contact you shortly.
          </p>
          <Button onClick={onClose}>Close</Button>
        </div>
      </Modal>
    );
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Contact C-Store Realty">
      <form onSubmit={handleSubmit} className="space-y-6">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            To
          </label>
          <input
            type="email"
            value={brokerData?.EmailAddress || ''}
            disabled
            className="w-full px-3 py-2 border border-gray-300 rounded-md bg-gray-50"
          />
        </div>

        <div>
          <label htmlFor="userEmail" className="block text-sm font-medium text-gray-700 mb-1">
            Your Email
          </label>
          <input
            id="userEmail"
            type="email"
            value={userEmail}
            onChange={(e) => setUserEmail(e.target.value)}
            required
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
            placeholder="Enter your email address"
          />
        </div>

        <div>
          <label htmlFor="subject" className="block text-sm font-medium text-gray-700 mb-1">
            Subject
          </label>
          <input
            id="subject"
            type="text"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            required
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
          />
        </div>

        <div>
          <label htmlFor="message" className="block text-sm font-medium text-gray-700 mb-1">
            Message
          </label>
          <textarea
            id="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
            rows={4}
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
            placeholder="Enter your message here..."
          />
        </div>

        <div className="flex justify-end space-x-4">
          <Button
            type="button"
            variant="ghost"
            onClick={onClose}
            disabled={sendingEmail}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            disabled={sendingEmail}
          >
            {sendingEmail ? 'Sending...' : 'Send Message'}
          </Button>
        </div>
      </form>
    </Modal>
  );
}
